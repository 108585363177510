<template>
	<div class="headertop">
		<div class="header" style="box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);">
		  <div class="zhong">
		    <div class="header_left">
		      <img
		        src="../assets/logo.png"
		        alt=""
		        style="height: 0.48rem; width: 0.48rem; cursor: pointer;"
				@click="$router.push('/')"
		      />
		      <div class="left_text" @click="$router.push('/')" >{{GLOBAL.BASE_TITLE}}</div>
		    </div>
			
			<div
			  @click.stop="toshows"
			  style="margin-left: 0.1rem !important"
			  class="selectnav"
			></div>
		  </div>
		  
		  <!-- 手机端下拉框 -->
		  <shoujixiala :shows="shows"></shoujixiala>
		  
		</div>
	</div>
</template>

<script>
	import shoujixiala from './shoujixiala.vue'
	export default {
		data(){
			return {
				shows:false
			}
		},
		methods: {
			toshows() {
				this.shows=!this.shows
			}
		},
		components:{
			shoujixiala
		},
		mounted() {
			document.documentElement.onclick=()=>{
				this.shows=false
			}
		}
		
	}
</script>

<style scoped lang="less">
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  position: relative;
	  .zhong{
		  display: flex; width: 94%; align-items: center;
		  justify-content: space-between;
		  @media screen and (max-width:435px){
			  width: 99%;
		  }
	  }
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 18px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
	
	// 下拉按钮
	.selectnav {
	  width: 30px;
	  height: 20px;
	  background: url(../assets/imgs/xiaimg.png) no-repeat;
	  background-size: 100% 100%;
	  margin-left: 10px;
	  display: none;
	  cursor: pointer;
	  @media screen and (max-width: 860px) {
	    display: block;
	    width: 24px;
	    height: 17px;
	  }
	}
</style>
