<template>
	<div>
		<!-- 表头 -->
		<el-row justify="center" type="flex" class="newblock_title" >
			<el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18">
				<el-row type="flex" align="middle" justify="space-between" class="newblock_title_size">
					<el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
						<span style="font-weight: bold;">Latest confirmed transaction</span>
					</el-col>
					<el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="hidden-xs-only">
						Timestamp
					</el-col>
					<el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" class="hidden-xs-only">
						Size
					</el-col>
					<el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" class="hidden-xs-only">
						Fee
					</el-col>
					<el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2" class="hidden-xs-only">
						Value
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<!-- 表内容 -->
		<el-row v-for="(item,index) in list"  >
			<div :class="{'hover-bg':index==hoverindex}"  @mouseover="hoverindex = index"  @mouseout="hoverindex = -1" >
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="border-bottom: 1px solid #EAEAEA; padding: 10rem 0; font-size: 16rem;" >
					<el-row type="flex" justify="center" >
						<!-- pc端显示 -->
						<el-col  :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  class="hidden-xs-only" >
							<el-row type="flex" justify="space-between" align="middle" style="padding: 10px 0; color: #707070;">
								<el-col :xs="10" :sm="9" :md="9" :lg="9" :xl="9">
									<div style="color: #0082FF; overflow: hidden;text-overflow: ellipsis;" class="lianjiecolor" :class="{lianjiehover:lianjie===index}" @mouseover="lianjie=index" @mouseout="lianjie=-1" >
										<router-link :to="`/hash/${item.hash}`">
											<span style="color: #0082FF;">
												{{item.hash}}
											</span>
										</router-link>
									</div>
								</el-col>
								<el-col :xs="5" :sm="4" :md="4" :lg="4" :xl="4">{{item.block_timestamp}}</el-col>
								<el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">{{item.size}}</el-col>
								<el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">{{item.fee}}</el-col>
								<el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">{{item.value}}</el-col>
							</el-row>
						</el-col>
						<!-- 移动端显示 -->
						<el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18"  class="hidden-sm-and-up" >
							<el-row>
								<el-col :xs="24" style="font-size: 12rem; color: #707070;">
									<el-row class="tops">
										<el-col :xs="5">TXID</el-col>
										<el-col :xs="18" class="xiahuaxian" style="overflow: hidden; text-overflow: ellipsis; color: #0082FF;cursor: pointer;" >
										<div @click="gohash(item.hash)">
											{{item.hash}}
										</div>
									</el-col>
									</el-row>
									<el-row class="tops">
										<el-col :xs="5">Timestamp</el-col>
										<el-col :xs="18">{{item.block_timestamp}}</el-col>
									</el-row>
									<el-row  class="tops">
										<el-col :xs="5">size</el-col>
										<el-col :xs="18">{{item.size}}</el-col>
									</el-row>
									<el-row  class="tops">
										<el-col :xs="5">fee</el-col>
										<el-col :xs="18">{{item.fee}}</el-col>
									</el-row>
									<el-row  class="tops">
										<el-col :xs="5">value</el-col>
										<el-col :xs="18">{{item.output_value}}</el-col>
									</el-row>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
				</el-col>
			</div>
		</el-row>
	</div>
</template>

<script>
	import 'element-ui/lib/theme-chalk/display.css';
	import {transactionlist} from "../../axios/index.js"
	export default {
		data(){
			return {
				list:[],
				hoverindex:-1,
				lianjie:-1
			}
		},
		async created() {
			// 获取最新交易列表数据
			let res = await transactionlist('/btc/transactions/');
			// 对返回的数据加工
			let ress = res.data.map((item)=>{
				return {
					hash:item.hash,
					block_timestamp:item.block_timestamp,
					fee:(item.fee/100000000).toString(),
					size:item.size,
					value:item.value/10000000
				}
			})
			// 合并到data中
			this.list = this.list.concat(ress)
			// 加工时间戳
			for(let i=0;i<this.list.length;i++){
				var dates= new Date(this.list[i].block_timestamp*1000)
				
				var y = dates.getFullYear();
				var m = dates.getMonth() + 1;    
				m = m < 10 ? ('0' + m) : m;    
				var d = dates.getDate();    
				d = d < 10 ? ('0' + d) : d;
				var h = dates.getHours();  
				h = h < 10 ? ('0' + h) : h;  
				var minute = dates.getMinutes();  
				minute = minute < 10 ? ('0' + minute) : minute;   
				var second = dates.getSeconds();  
				second = second < 10 ? ('0' + second) : second; 
				this.list[i].block_timestamp= y+"-"+m+"-"+d+" "+h+':'+minute+":"+second
			}
		},
		methods:{
			gohash(value){
				this.$router.push(`/hash/${value}`)
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.newblock_title{
		background-color: #EFEFEF;
		padding: 20rem 0;
		@media screen and (max-width:766px){
			padding: 10rem 0;
		}
		.newblock_title_size{
			font-size: 16rem;
			
		}
	}
	
	.hover-bg{
		overflow: hidden;
		background-color:#FEFEFE;
	}
	.tops{
		margin-top: 15rem;
		padding-bottom: 10rem;
	}
	.xiahuaxian:hover{
		text-decoration: underline;
	}
</style>
