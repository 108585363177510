import axios from "axios" 
import {Message} from "element-ui"

// 创建axios实例
const requests = new axios.create({
	// 请求跟路径基础URL
	baseURL:'http://192.168.36.134:7102',
	// 请求超时时间
	timeout:10000
})

// 请求拦截器
requests.interceptors.request.use((config)=>{
	// config 是请求的配置对象，里面有一个很重要的东西header请求头
	return config
},(error)=>{
	return Promise.reject(error)
})
// 相应拦截器
requests.interceptors.response.use((res)=>{
	// 拿到返回的信息，可对信息加工
	return res.data
	
},(error)=>{
	Message({
		message:error,
		type:"error"
	})
	return error
})

export default requests