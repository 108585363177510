<template>
	<div style="overflow: hidden;">
		<div class="tranpage hide">
			<div class="list" v-for="(item,index) in list" :key="index">
				<div class="list_title">
					<div class="title_left tranpagelink">
						<router-link :to="`/hash/${item.hash}`" style="width: 100%;">
							<span style="color: #0082FF; width: 100%;" class="textundelin">
								<router-link  :to="`/hash/${item.hash}`" style="font-size: 14px;">
									{{item.hash}}
								</router-link>
							</span>
						</router-link>
					</div>
					<div class="imgwei">
						
					</div>
					<div class="title_right" v-if="$route.meta.types!=='address'">
						<div style=" margin-right: 20px;">Fee</div>
						<div :class="{addressfee:$route.meta.types==='address',blockfee:$route.meta.types!=='address'}">{{item.fee}} {{peizhi[0].coin}}</div>
						<div class="timestamps" v-if="$route.meta.types==='address'">
							{{item.timestamp}}
						</div>
					</div>
					<div class="title_right_address" v-if="$route.meta.types==='address'">
						<div style=" margin-right: 20px;">Fee</div>
						<div :class="{addressfee:$route.meta.types==='address',blockfee:$route.meta.types!=='address'}">{{item.fee}} {{peizhi[0].coin}} </div>
						<div class="timestamps" v-if="$route.meta.types==='address'">
							{{item.timestamp}}
						</div>
					</div>
				</div>
				<div class="list_con" >
					<div class="con_left" >
						<div style="padding-left: 10px;box-sizing: border-box;">
							<div class="row_left" v-for="(inputss,key) in item.inputs" :key="key"
								style="border: 2px solid #EBEBEB;  border-radius: 6px; margin-bottom: 8px; padding-left: 6px; box-sizing: border-box;">
								<!-- 查地址显示 -->
								<div style="width: 72%;word-wrap: break-word;" v-if="$route.meta.types==='address'"  >
									<router-link :to="`/address/${inputss.addresses}`" v-if="inputss.addresses[0]!=='Coinbase'">
										<div style="word-wrap: break-word; color: #0082FF;" class="tranpagelink" :class="{'noclass':inputss.addresses=='Coinbase'}"
											v-text="inputss.addresses"
										></div>
									</router-link>
									<div style="word-wrap: break-word; color: #0082FF;" class="tranpagelink" :class="{'noclass':inputss.addresses=='Coinbase'}" v-else>
										Coinbase
									</div>
								</div>
								<!-- 查区块显示 -->
								<div style="width: 72%;word-wrap: break-word;" v-if="$route.meta.types!=='address'"  >
									<router-link :to="`/address/${inputss.addresses}`" v-if="inputss.addresses[0]!=='Coinbase'">
										<div style="word-wrap: break-word; color: #0082FF;" class="tranpagelink" :class="{'noclass':inputss.addresses=='Coinbase'}"
											v-text="inputss.addresses"
										></div>
									</router-link>
									<div style="word-wrap: break-word; color: #0082FF;" class="tranpagelink" :class="{'noclass':inputss.addresses=='Coinbase'}" v-else>
										Coinbase
									</div>
								</div>
								
								<!-- 查地址显示 -->
								<div class="shuzi" v-if="$route.meta.types==='address'" v-text="inputss.addresses=='Coinbase'?item.total_vout:inputss.value" >
									
								</div>
								<!-- 查区块显示 -->
								<div class="shuzi" v-if="$route.meta.types!=='address'" >
									{{inputss.value}}
								</div>
							</div>
						</div>
					</div>
					<div class="imgwei">
						<img src="../../assets/imgs/rightjiantou.png" />
					</div>
					<div class="con_right">
						<!-- 便利 -->
						<div style="box-sizing: border-box; ">
							<div v-for="(outputss,key) in item.outputs" v-if="outputss.addresses.length!==0" :key="key"
								style="border: 2px solid #EBEBEB; margin-bottom: 8px; border-radius: 6px; padding-left: 6px; box-sizing: border-box;
								padding: 20px 6px; box-sizing: border-box;">
								<div style="display: flex; justify-content: space-between; font-size: 14px;margin: 0px 0; padding-right: 20px; ">
									<div style="width: 72%; word-wrap: break-word; " class="tranpagelink">
										<div  v-if="($route.meta.types!=='address')">
											<div style="word-wrap: break-word; color: black; cursor: text;" v-if="outputss.addresses[0] === 'OP_RETURN'">{{outputss.addresses[0]}}</div>
											<router-link :to="`/address/${outputss.addresses}`"   v-else>
												<div style="word-wrap: break-word; " >{{outputss.addresses[0]}}</div>
											</router-link> 
										</div>
										
										<div v-if="$route.meta.types==='address'">
											<div style="word-wrap: break-word; color: black; cursor: text;" v-if="outputss.addresses[0] === 'OP_RETURN'">{{outputss.addresses[0]}}</div>
											<router-link :to="`/address/${outputss.addresses}`"   v-else>
												<div style="word-wrap: break-word; " >{{outputss.addresses[0]}}</div>
											</router-link>
										</div>
										
									</div>
									<!-- 查区块显示 -->
									<div v-if="$route.meta.types!=='address'">
										{{outputss.value}} 
									</div>
									<!-- 查地址显示 -->
									<div v-if="$route.meta.types==='address'">{{outputss.value}}</div>
								</div>
							</div>
						</div>
						<div class="btn_group">
							<!-- 这里的fee是null所以不显示 -->
							<button>{{item.total_vout}} {{peizhi[0].coin}}</button>
							<button v-if="$route.meta.types==='address'" 
								:class="{'bg-red':item.output_count==0,'bg-blue':item.output_count!=0&&item.output_count!=null&&item.output_count!=undefined}"
								v-text="(item.output_count!==0)?`${item.output_count} CONFIRMATIONS`:`UNCONFIRMED`"
							>{{item.output_count}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 手机端 -->
		<div class="show shouji" >
			<div class="shouji_item" v-for="(item,index) in list" :key="index">
				
				<div class="item_fee">
					<div style="width: 40px; flex-shrink: 0;">Fee</div>
					<div v-if="$route.meta.types!=='address'">{{item.fee}} {{peizhi[0].coin}}</div>
					<div v-if="$route.meta.types==='address'" style="width: 700px;">{{item.fee}} {{peizhi[0].coin}}</div>
					<div class="timestamps" v-if="$route.meta.types==='address'" style="width:130px; flex-shrink: 0;">
						{{item.timestamp}}
					</div>
				</div>
				<div class="item_hash">
					<p class="undelines" style="width: 98%; word-wrap: break-word; padding: 0px 0; box-sizing: border-box;">
						<router-link :to="`/hash/${item.hash}`">
							{{item.hash}}
						</router-link>
					</p>
				</div>
				<div class="item_input">
					<div v-for="(inputss,key) in item.inputs" class="buchong" :key="key">
						<!-- 查地址显示 -->
						<div class="input_id undelines" style="width: 100%; font-size: 12px;" v-if="$route.meta.types==='address'">
							<router-link :to="`/address/${inputss.addresses}`">
								<div :class="{noid:inputss.addresses=='Coinbase'}" v-text="inputss.addresses!==''?`${inputss.addresses}`:''"></div>
							</router-link> 
						</div>
						<!-- 查区块显示 -->
						<div class="input_id " style="width: 100%; font-size: 12px;" v-if="$route.meta.types!=='address'">
							<router-link :to="`/address/${inputss.addresses}`">
								<div class="undelines" :class="{noid:inputss.addresses[0]=='Coinbase'}" v-text="inputss.addresses!==''?`${inputss.addresses}`:''"></div>
							</router-link> 
						</div>
						
						
						<!-- 查地址显示 -->
						<div class="input_value" v-if="$route.meta.types==='address'" v-text="inputss.addresses=='Coinbase'?item.total_vout:inputss.value">
							
						</div>
						<!-- 查区块显示 -->
						<div class="input_value" v-if="$route.meta.types!=='address'" style="font-size: 12px;">
							{{inputss.value}}
						</div>
					</div>
				</div>
				<div class="item_img">
					<img src="../../assets/imgs/bottomjiantou.png">
				</div>
				<div class="item_output">
					<div class="output_item" v-for="(outputss,key) in item.outputs" v-if="outputss.addresses.length!==0" :key="key" >
						<div class="output_item_id undelines">
							<router-link :to="`/address/${outputss.addresses}`">
								<div>{{outputss.addresses[0]}}</div>
							</router-link> 
						</div>
						<!-- 查地址显示 -->
						<div class="output_item_value" v-if="$route.meta.types==='address'">
							{{outputss.value}}
						</div>
						<!-- 查区块显示 -->
						<div v-if="$route.meta.types!=='address'">
							{{outputss.value}} 
						</div>
						
					</div>
				</div>
				<!-- 按钮 -->
				<div class="s_button" v-if="$route.meta.types!=='address'">
					<button>{{item.total_vout}} {{peizhi[0].coin}}</button>
				</div>
				<div class="address_button" v-if="$route.meta.types==='address'">
					<button class="btn1" 
						:class="{'bg-red':item.output_count==0,'bg-blue':item.output_count!=0&&item.output_count!=null&&item.output_count!=undefined}" 
						v-text="(item.output_count!==0)?`${item.output_count} CONFIRMATIONS`:`UNCONFIRMED`"></button>
						
					<button class="btn2" :class="{'bg_green':item.total_vout>=0,'bg_yellow':item.total_vout<0}" >{{item.total_vout}} {{peizhi[0].coin}}</button>
				</div>
			</div>
		</div>
	</div>
	
	
</template>

<script>
	import Decimal from "decimal.js"
	export default {
		data(){
			return {
				btncode:0,
				showsize:3,
				outshowsize:2,
				inputbiao:null,
				Decimal:function(value){
					return new Decimal(value).mul(new Decimal(100000000))
				},
				peizhi:''
			}
		},
		props:['list'],
		methods:{
			toggles(){
				if(this.showsize==3){
					this.showsize=100
				}else{
					this.showsize=3
				}
			},
			outtoggles(){
				if(this.outshowsize==3){
					this.outshowsize=100
				}else{
					this.outshowsize=3
				}
			},
			goaddress(address){
				this.$router.push(`/address/${address}`)
				
			}
			
		},
		created() {
			this.peizhi = this.GLOBAL.option.filter(item=>{
				// console.log(window.location.host==item.api);
				// window.location.host
				// 开发环境
				if(item.api==item.api){
					return item
				}
				// 生产环境
				// if(window.location.host==item.api){
				// 	return item
				// }
			})
			console.log(this.$route.meta.types);	
		}
	}
</script>

 <style lang="less" scoped>
	 
	 .tranpage{
		 width: 100%;
		 background-color: #FFFFFF;
		 padding-top: 28px;
		 padding-bottom: 40px;
		 .list{
			 width: 13.79rem;
			 // 得删除
			 margin: 20px auto;
			 padding-top: 10px;
			 // padding-top: 20px;
			 padding-bottom: 2px;
			 box-sizing: border-box;
			 border: 1px solid #E8E8E8;
			 border-radius: 6px;
			 transition: box-shadow 0.4s;
			 @media screen and (max-width:1240px){
				 width: 92%
			 }
			 .list_title{
				 width: 96%;
				 height: 47px;
				 margin: 0 auto;
				 display: flex;
				 // background-color: #EFF5FB;
				 border-radius: 6px;
				 
				justify-content: space-between;
				 .title_left{
					 width: 46%;
					 display: flex;
					 word-wrap: break-word;
					 align-items: center;
					 height: 47px;
					 font-size: 16px;
					 padding-left: 10px;
					 box-sizing: border-box;
					 // overflow: hidden;
					 // text-overflow: ellipsis;
					 color: #3498E1;
					 
				 }
				 .imgwei{
					 width: 47px;
					 height: 47px;
					 flex-shrink: 0;
				 }
				 .title_right{
					width: 45%;
					 height: 47px;
					 line-height: 47px;
					 font-size: 14px;
					 padding-right: 20px;
					 box-sizing: border-box;
					 color: #242424;
					 display: flex;
					 justify-content: flex-end;
					 .timestamps{
						width: 1.9rem;
						text-align: right;
					 }
				 }
				 .title_right_address{
					 width: 45%;
					  height: 47px;
					  line-height: 47px;
					  font-size: 14px;
					  padding-right: 20px;
					  box-sizing: border-box;
					  color: #242424;
					  display: flex;
					  justify-content: space-between;
					  .timestamps{
					 	width: 1.9rem;
					 	text-align: right;
					  }
				 }
			 }
			 
				 
			 .con_title{
				 width: 96%;
				 margin: 0 auto;
				 display: flex;
				 justify-content: space-between;
				 
				 margin-top: 15px;
				 margin-bottom: 4px;
				.con_title_left{
					font-size: 16px;
					font-weight: bold;
					width: 45%;
					
				}
				.imgwei{
					 width: 47px;
					 flex-shrink: 0;
				}
				.con_title_right{
					font-size: 16px;
					
					font-weight: bold;
					width: 45%;
				}
			 }
			 
			 .list_con{
				 width: 96%;
				 margin: 0 auto;
				 display: flex;
				 justify-content: space-between;
				 
				 align-content: flex-start;
				 .con_left{
					 width: 46%;
					 border-radius: 6px;
					 box-sizing: border-box;
					 padding: 10px 0;
					 box-sizing: border-box;
					 font-size: 14px;
					 .shuzi{
						 margin-left: 20px;
					 }
					
					 .row_left{
						 width: 100%;
						 padding: 18px 0;
						 line-height: 16px;
						 box-sizing: border-box;
						 align-content: flex-start;
						 display: flex;
					 }
				 }
				 .imgwei{
					 width: 47px;
					 height: 47px;
					 padding: 10px 0;
					 img{
						 width: 100%;
						 height: 100%;
					 }
				 }
				 .con_right{
					 width: 45%;
					 border-radius: 6px;
					 padding: 10px 0;
					 box-sizing: border-box;
					 
				 }
				 .btn_group{
					 display: flex;
					 flex-direction: row-reverse;
					justify-content: space-between;
					margin-top: 17px;
					button{
						width: 46%;
						height: 43px;
						border: none;
						background-color: #179A6A;
						font-size: 14px;
						border-radius: 6px;
						color: #FFFFFF;
					}
				 }
			 }
			 
		 }
		 .list:hover{
			 box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		 }
	 }
	 
	.hide{
		@media screen and (max-width:1100px){
			display:none ;
		}
	}
	.show{
		@media screen and (max-width:1100px){
			display: block !important;
		}
	}
	
	.shouji{
		display: none;
		width: 100%;
		overflow: hidden;
		background-color: #F7F7F7;
		.shouji_item{
			width: 96%;
			margin: 0 auto;
			margin-top: 30px;
			background-color: #FFFFFF;
			margin-bottom: 10px;
			border: 1px solid #E8E8E8;
			padding: 6px 0px 10px;
			box-sizing: border-box;
			border-radius: 6px;
			transition: box-shadow 0.4s;
			.item_hash{
				width: 100%;
				display: flex;
				align-items: center;
				padding: 5px 20px;
				box-sizing: border-box;
				word-wrap: break-word;
				font-size: 14px;
				color: #3498E1;
				// background-color: #EFF5FB;
			}
			.item_fee{
				width: 100%;
				box-sizing: border-box;
				height: 40px;
				color: #8392A5;
				// margin-top: 15px;
				margin-bottom: 0px;
				padding: 5px 20px;
				// background-color: #EFF5FB;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
			}
			.item_input{
				width: 100%;
				margin-top: 0px;
				padding: 1px 20px 5px;
				box-sizing: border-box;
				
				font-size: 16px;
				.buchong{
					display: flex;
					border: 2px solid #EBEBEB;
					padding: 9px 0.21rem;
					box-sizing: border-box;
					border-radius: 6px;
					justify-content: space-between;
					align-items: center;
					margin-top: 10px;
					@media screen and (max-width:746px){
						display: block;
					}
					.input_id{
						font-size: 16px;
						line-height: 1;
						color: #3498E1;
						word-wrap: break-word;
						@media screen and (max-width:747px){
							margin-bottom: 6px;
						}
					}
					.input_id:first-child{
						color: rgb(131, 146, 165);
						text-decoration: none;
					}
					.input_value{
						font-size: 14px;
					}
				}
				
			}
			.item_img{
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				margin: 10px auto;
				align-items: center;
				border-radius: 50%;
				background-color: #EFF5FB;
				img{
					width: 36px;
					height: 36px;
				}
			}
			
			.item_output{
				width: 100%;
				padding: 0px 20px;
				font-size: 12px;
				margin-top: 15px;
				// background-color: #EFF5FB;
				box-sizing: border-box;
				.output_item{
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 2px solid #EBEBEB;
					padding:10px 0.21rem;
					border-radius: 6px;
					margin-top: 10px;
					@media screen and (max-width:746px){
						display: block;
						
					}
					.output_item_id{
						color: #3498E1;
						@media screen and (max-width:747px){
							margin-bottom: 6px;
						}
						word-wrap: break-word;
					}
					.output_item_value{
						font-size: 14px !important;
					}
				}
			}
			.s_button{
				padding: 0px 20px;
				box-sizing: border-box;
				button{
					width: 100%;
					padding: 10px 0;
					background-color: #179A6A;
					font-size: 14px;
					border-radius: 5px;
					margin-top: 20px;
					color: #FFFFFF;
				}
			}
		}
		.shouji_item:hover{
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		}
	}
	 
	 
	 
	 .bg-red{
		 background-color: #FF5858 !important;
	 }
	 .bg-blue{
		 background-color: #3E5985 !important;
	 }
	 .bg_yellow{
		 background-color: #FF7307 !important;
	 }
	 .bg_green{
		 background-color: #179A6A !important;
	 }
	 
	 .undelines:hover{
		 cursor: pointer;
		 text-decoration: underline !important;
	 }
	 .aaa:hover{
		text-decoration:none
	 }
	 .address_button{
		 width: 100%;
		 display: flex;
		 justify-content: space-between;
		 flex-wrap: wrap;
		 margin-top: 10px;
		 padding: 0 20px;
		 box-sizing: border-box;
		 .btn1,.btn2{
			 // width: 44%;
			 width: 100%;
			 height: 40px;
			 color: white;
			 margin-bottom: 10px;
			 font-size: 14px;
			 border-radius: 5px;
			 background-color: #00FFFF;
		 }
		 .btn1{
			 line-height: 1.2;
		 }
		
	 }
	 
	 
	 
	 
	 .noid{
		 color: rgb(131, 146, 165);
	 }
	 .noid:hover{
		 cursor: auto !important;
		 text-decoration:none !important;
	 }
	 
	 .noclass{
		
		
	 }
	 .noclass:first-child{
		  color: rgb(131, 146, 165) !important; 
		  text-decoration: none;
		  cursor: auto;
	 }
	 
	 
	 
	 .tranpagelink{
		 word-wrap: break-word !important;
	 }
	 
	 
	 
	 
	 
	 .addressfee{
		 width: 2rem;
	 }
	 .blockfee{
		 width: auto;
	 }
	.textundelin{
		word-wrap: break-word;
		color: #3498E1; 
		cursor: pointer;
		@media screen and (max-width:766px){
			font-size: 14rem;
		}
	}
	.textundelin:hover{
		text-decoration: underline;
	}
	
	
	
	
</style>
