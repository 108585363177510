<template>
  <div style="background: #f7f7f7">
    <!-- indextop部分 -->
    <indextop></indextop>
    <!-- header的下拉菜单列表 -->
    <!-- <topxiala></topxiala> -->

	<!-- 搜索框 -->
    <div class="search">
      <seachs></seachs>
    </div>
	
	<!-- 小导航 -->
	<div class="minnav">
	  <div class="nav_title">
	    <div class="xiao">
	      <div class="hands" @click="$router.push('/')">{{xiaobiaoti}}</div>
	      <div class="triangle"></div>
	      <div>Blocks</div>
	    </div>
	
	    <div class="tishiyu">
	    </div>
	  </div>
	  <div class="nav_right" id="xialakuang" >
	    Show &nbsp;
	    <div class="nav_btn" id="chooselist">
	      <input
	        type="button"
	        class="btn_input"
			:value="pagesum"
			@click="tochoose=!tochoose"
	      />
	      <div class="btn_left" @click="tochoose=!tochoose">
	        <div class="el-icon-caret-top icon_btn"></div>
	        <div class="el-icon-caret-bottom icon_btn"></div>
	      </div>
	      <div class="nav_list" v-show="tochoose">
	        <div class="nav_list_one" @click="choosenum(20)">20</div>
	        <div class="nav_list_one" @click="choosenum(50)">50</div>
	        <div class="nav_list_one" @click="choosenum(100)">100</div>
	      </div>
	    </div>
	    &nbsp; Records
	  </div>
	</div>
	
	
	<!-- 内容 -->
	<div style="min-height: 700px;">
		<div class="con">
			<!-- 标题 -->
			<div class="yinchang">
				<div style="width:60px; flex-shrink: 0;">
					Block
				</div>
				<div style="width: 15%; box-sizing:border-box; min-width: 140px; flex-shrink:0">
					Timestamp
				</div>
				<div style="width: 34%; min-width: 350px; flex-shrink: 0;" id="xialakuang">
					Miner
				</div>
				<div style="width: 10%; min-width: 100px; flex-shrink: 0;">
					Transactions
				</div>
				<div style="width: 15%; min-width: 150px; flex-shrink: 0;">
					Size(KB)
				</div>

			</div>
			<!-- item -->
			<div class="item_con">
				<!-- 便利 -->
				<div class="list_item" v-for="(value,index) in list" :key="index">
					<div class="blocks">
						<p>Block:</p>
						<div>
						
						<router-link :to="`/block/${value.number}`">
								<span class="link">
									{{value.number}}
								</span>
							</router-link>
						</div>
					</div>
					<div class="address">
							<div class="shouji_address">
								Timestamp:
							</div>
							<div style="overflow: hidden ;text-overflow: ellipsis;">
								{{value.timestamp | formData}}
							</div>
					</div>
					<div class="nametag">
						<div class="shouji_nametag">
							Miner:
						</div>
						<div style="overflow: hidden; text-overflow: ellipsis; margin-top: 0.8px;">
							<router-link :to="`/address/${value.miner}`">
								<span class="links">
									{{value.miner}}
								</span>
							</router-link>
							
						</div>
					</div>
					<div class="trantag">
						<div class="shouji_trantag">
							Transactions:
						</div>
						<div style="overflow: hidden; text-overflow: ellipsis; margin-top: 0.8px;">
							{{value.transaction_count}}
						</div>
					</div>
					<div class="sizetag">
						<div class="shouji_sizetag">
							size:
						</div>
						<div style="overflow: hidden; text-overflow: ellipsis; margin-top: 0.8px;">
							{{value.size | units}}
						</div>
					</div>
				</div>
			</div>
			<!-- 隐藏盒子 -->
			<div
			 v-show="false"
			  class="yinchang xincang"
			>
			  <!-- 蓝盒子 -->
			  <div class="inner_left"></div>
			  <div
			    style="font-size: 15px; color: rgb(0, 130, 255); margin-left: 20px"
			  >
			    There are no matching entries
			  </div>
			</div>
		</div>
	</div>
	
	
	<!-- 页码 -->
   <div class="changepage">
      <el-pagination
		background
        layout="prev, pager, next"
        :total="1000"
        :page-size="pagesum"
        :pager-count="5"
        :current-page.sync="currentpage"
      >
      </el-pagination>
    </div>
    <!-- <foot style="margin-top: 18px"></foot> -->
  </div>
</template>

<script>
import indextop from "../components/indextop.vue"
import seachs from "../components/header/header.vue"
import {blocks} from "../axios/index/api.js"

export default {
	data(){
		return {
			list:[],
			// 当前页码
			currentpage:1,
			tochoose:false,
			// 每页条数
			pagesum:20,
			lastblock:null,
			firstblock:null,
			xiaobiaoti:""
		}
	},
	components:{
		indextop,
		seachs
	},
	filters:{
		formData(value) {
		    let data = new Date((value) * 1000);
		    let y = data.getFullYear();
		    let MM = data.getMonth() + 1;
		    MM = MM < 10 ? "0" + MM : MM;
		    let d = data.getDate();
		    d = d < 10 ? "0" + d : d;
		    let h = data.getHours();
		    h = h < 10 ? "0" + h : h;
		    let m = data.getMinutes();
		    m = m < 10 ? "0" + m : m;
		    let s = data.getSeconds();
		    s = s < 10 ? "0" + s : s;
		    return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
		},
		units(value) {
			let v = (value /1000).toFixed(2)
			return v
		}
	},
	async created() {
		let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
		
		
		let res = await blocks({
			size:20,
			block_number:this.lastblock
		})
		
		this.list = res.data
		this.lastblock = this.list.slice(-1)[0].number
		this.firstblock = this.list[0].number
		console.log(this.firstblock)
	},
	methods:{
		qingqiu(){
			
		},
		choosenum(value){
			this.pagesum = value
			console.log(value)
			this.$router.push(`/blocks?size=${this.pagesum}`)
			this.tochoose=false
			this.currentpage = 1
		}	
	},
	watch:{
		currentpage:function(newval,oldval){
			if(newval>oldval){
				this.$router.push(`/blocks?size=${this.pagesum}&block_number=${this.lastblock}`)
			}else{
				this.$router.push(`/blocks?size=${this.pagesum}&block_number=${this.firstblock}&direction=1`)
			}
			
		}
	},
	async beforeRouteUpdate(to,from,next) {
		next()
		let res = await blocks({
			size:to.query.size,
			block_number:to.query.block_number,
			direction:to.query.direction
		})
		
		this.list = res.data
		this.lastblock = this.list.slice(-1)[0].number
		this.firstblock = this.list[0].number
		
		
		
	}

}
</script>
<style lang="less" scoped>

.changepage{
	text-align: center;
	margin: 15px 0;
}

.search {
  margin: 28px 0;
}
// 小导航
.minnav {
  width: 13.79rem;
  margin: 0 auto;
  @media screen and (max-width: 1240px) {
    width: 92%;
  }
  @media screen and (max-width: 589px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.nav_title {
  font-size: 16px;
  color: #242424;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 589px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .xiao {
    display: flex;
    @media screen and (max-width: 589px) {
      width: 100%;
    }
  }
  .tishiyu {
    margin-left: 16px;
    color: #8392a5;
    @media screen and (max-width: 589px) {
      margin-left: 0px;
    }
  }
}
.triangle {
  width: 0;
  height: 0;
  border-left: 6px solid #242424;
  border-right: 0rem solid #242424;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  margin: 3px 6px;
}
.nav_right {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #707070;
  opacity: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 950px) {
    margin-left: auto;
    margin-top: 5px;
  }
  .nav_btn {
    width: 50px;
    height: 30px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #d2d2d2;
    opacity: 1;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .nav_btn:active {
    border: 1px solid #0082ff;
  }

  .btn_input {
    width: 30px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 19px;
    color: #707070;
    opacity: 1;
    background: #fff;
    border-radius: 3px;
  }
  .btn_left {
    height: 100%;
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .icon_btn {
    height: 12px;
    width: 12px;
    text-align: center;
    font-size: 12px;
  }
  .nav_list {
    width: 48px;
    background: #fff;
    position: absolute;
    top: 31px;
    border: 1px solid rgba(0, 0, 0, 0.541);
  }
  .nav_list_one {
    height: 20px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    color: #000;
  }
  .nav_list_one:hover {
    background: #0082ff;
  }
}

@media screen and(max-width:860px) {
  .minnav {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin-bottom: 15px;
  }
}

// 内容
.con {
  width: 100%;
  margin-top: 7px;
  @media screen and (max-width: 770px) {
    max-height: 7000px;
  }
  .yinchang {
    color: #242424;
    width: 13.79rem;
    padding: 12px 19px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
    background-color: #ffffff;
    border-radius: 2px;
	font-size: 14px;
    margin: 10px auto;
    display: none;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1240px) {
      width: 92%;
    }
	@media screen and (min-width:890px){
		display: flex;
	}
  }
  .item_con{
	  .list_item {
	    color: #8392a5;
	    width: 13.79rem;
	    padding: 19px 19px ;
	    box-sizing: border-box;
	    border: 1px solid #ebebeb;
	    background-color: #ffffff;
	    border-radius: 2px;
	    margin: 10px auto;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
		font-size: 15px;
	    // @media screen and (max-width: 770px) {
	    //   flex-direction: column;
	    //   align-items: flex-start;
	    // }
	    @media screen and (max-width: 1240px) {
	      width: 92%;
	    }
		@media screen and (max-width:890px){
			flex-direction: column;
			align-items: flex-start;
			
		}
		.blocks{
			display: flex;
			align-items: center;
			@media screen and (max-width:890px){
				padding: 4px 0 10px;
				width: 100%;
			}
			div{
				width: 60px;
				flex-shrink: 0;
				height: 46px;
				color: #FFFFFF;
				background: #0082FF; 
				border-radius: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (max-width:890px){
					width: 92px;
					height: 27px;
				}
			}
			p{
				width: 100px;
				
				display: none;
				@media screen and (max-width:890px){
					display: block;
				}
			}
			
		}
		.shouji_blcok{
			font-size: 15px;
			color: #242424;
			font-weight: bold;
			padding-bottom: 5px;
			width: 100%;
			border-bottom: 1px solid #DFDFDF;
			display: none;
			margin-bottom: 5px;
			@media screen and (max-width:890px){
				display: block;
			}
		}
		.address{
			width: 15%;
			min-width: 140px;
			white-space: nowrap;
			text-overflow: ellipsis;
			flex-shrink: 0;
			display: flex;
			@media screen and (max-width:890px){
				width: 100%
			}
			.shouji_address{
				display: none;
				width: 100px;
				flex-shrink: 0;
				@media screen and (max-width:890px){
					display: block;
				}
			}
			
		}
		.nametag{
			width:34%;
			min-width: 350px;
			height: 16px;
			display: flex;
			flex-shrink: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@media screen and (max-width:890px){
				width: 100%;
				min-width: 0;
				flex-shrink:initial
			}
			.shouji_nametag{
				display: none;
				width: 100px;
				flex-shrink: 0;
				@media screen and (max-width:890px){
					display: block;
				}
			}
		}
		
		.trantag{
			width:10%;
			min-width: 100px;
			height: 16px;
			display: flex;
			flex-shrink: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@media screen and (max-width:890px){
				width: 100%
			}
			.shouji_trantag{
				display: none;
				width: 100px;
				flex-shrink: 0;
				@media screen and (max-width:890px){
					display: block;
				}
			}
		}
		
		.sizetag{
			width:15%;
			min-width: 150px;
			height: 16px;
			display: flex;
			flex-shrink: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@media screen and (max-width:890px){
				width: 100%
			}
			.shouji_sizetag{
				display: none;
				width: 100px;
				flex-shrink: 0;
				@media screen and (max-width:890px){
					display: block;
				}
			}
		}
		
		
		
		
		
		
		.balance{
			width: 16%;
			min-width: 130px;
			margin-left: 10px;
			display: flex;
			@media screen and (max-width:890px){
				width: 100%
			}
			
			.shouji_balance{
				display: none;
				width: 80px;
				flex-shrink: 0;
				@media screen and (max-width:890px){
					display: block;
				}
			}
			
			
			
		}
		
		
		
		.address,.balance,.nametag,.trantag,.sizetag{
			@media screen and (max-width:890px){
				margin-left: 0px;
				padding: 10px 0;
			}
		}
  }
  
  }
}


.pages {
  text-align: center;
  margin-top: 20px;
}

.link {
  color: white;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.links {
  color: #0082ff;
  cursor: pointer;
}
.links:hover {
  text-decoration: underline;
}
.inner_left {
  width: 85px;
  height: 60px;
  background: rgba(0, 130, 255);
  opacity: 1;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin-left: 18px;
  @media screen and(max-width:860px) {
    display: none;
  }
}
.shoujiselect{
	display: none;
	@media screen and (max-width:890px){
		display: inline-block;
	}
}
.xincang{
	  padding: 18px 0 !important;
	  background-color: #FFFFFF  !important;
	  margin-top: 12px  !important; 
	  justify-content: flex-start  !important;
	  width: 13.79rem  !important;
	  margin: 0 auto  !important;
	  display: flex;
	  @media screen and (max-width:1240px){
		  width: 92%  !important;
	  }
	  align-items: center;
  }
</style>
<style lang="less">
.input_number {
  height: 30px;
  .el-input-number__decrease {
    border: none !important;
    background: none;
    width: 20px;
  }
  .el-input-number__increase {
    border: none !important;
    background: none;
    width: 20px;
    line-height: 10px !important;
  }
  .el-input__inner {
    height: 30px;
    line-height: 30px;
    padding-right: 10px !important;
    padding-left: 1px !important;
  }
}
.el-input-number {
  line-height: 30px !important;
  width: 60px;
  .el-input__inner {
    padding-right: 10px;
  }
}
.links {
  cursor: pointer;
}
.links:hover {
  text-decoration: underline;
}
</style>