import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currency:"BTC",
	viewbutton:true
  },
  mutations: {
	//改变币种 
    SETCURRENCY(states,value){
		states.currency=value
	},
	setviewbutton(states,value){
		states.viewbutton=!states.viewbutton
	}
  },
  actions:{
	  setcurrency(textcontent,value){
		textcontent.commit('SETCURRENCY',value)
	  }
  }
})

export default store