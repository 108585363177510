<template>
  <div id="app">
	<!-- 搜索内容部分 -->
	<keep-alive :include="['home']" >
		<router-view>
			
		</router-view>
	</keep-alive>
	
	<footer_></footer_>
  </div>
</template>

<script>
import header_ from "./components/header/header.vue"
import footer_ from "./components/footer/footer.vue"

export default {
  name: 'App',
  components: {
	  header_,
	  footer_
  },
  beforeCreate() {
	document.title = this.GLOBAL.titles
	  
  	let flag = navigator.userAgent.match(
  	  /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  	);
	
  	if (flag) {
  	  // alert("手机端");
  	  localStorage.setItem("isMobile", true);
  	  // console.log('Mobile',localStorage)
  	  // this.$router.replace('/m_index');
  	} else {
  	  localStorage.setItem("isMobile", false);
  	  // console.log('PC',localStorage)
  	
  	  // this.$router.replace('/pc_index');
  	}
	
	
  },
  methods: {
    //App.vue
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}
</script>



<style lang="less">
	#app{
		background-color: #F9FAFB;
	}
	.logos{
			width: 50px;
			height: 50px;
			cursor: pointer;
			@media screen and (max-width:766px){
				width: 50px;
				height: 50px;
			}
	}
	
	.logo_text{
		font-size: 20px;
	}

</style>
