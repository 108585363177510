import requests from "./request.js"

// 查询首页区块列表
export const blackList = (size,page="")=>{
	// 返回promise对象
	return requests({url:`/btc/blocks/?size=${size}`,method:"get"})
}

export const transactionlist = (url,method="get")=>{
	return requests({url,method})
}

export const addressinfo = (address)=>{
	 return requests({url:`btc/addrinfo?address=${address}`})
} 