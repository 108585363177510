<template>
  <div>
    <div class="search">
      <div class="search_from">
        <input
          class="search_input"
          type="search"
          v-model="searchtext"
          @blur="deleteyin"
          @focus="addyin"
          placeholder="Search your address, transaction or block"
        />
        <button class="serach_button" @click="routeraddress">
          <img src="../../assets/imgs/search.png" />
        </button>
      </div>
      <!-- 下拉框开始 -->
      <div class="rightselect">
		<!-- Blocks下拉 -->
        <div
          class="selectnav"
          :class="{ bghui: show1 }"
          @click.stop="show1toggle"
        >
          <div>
            <div>
				<router-link :to="`/blocks`">
					<span style="margin-right: 10px;font-size: 15px;" :class="{ colors: colorss }" class="colosss">Blocks</span>
				</router-link>
            </div>
          </div>
        </div>
		<!-- Transactions下拉 -->
        <div
          class="selectnav"
          :class="{ bghui: show2 }"
          @click.stop="show2toggle"
        >
          <div>
			  <router-link :to="`/pending`">
				  <span class="colosss" style="margin-right: 10px;font-size: 15px;" :class="{ colors: colorss }">Pending</span>
			  </router-link>
        </div>
		<!-- token下拉 -->
        <div
		v-show="false"
          class="selectnav"
          :class="{ bghui: show3 }"
          @click.stop="show3toggle"
        >
          <div>
            <span style="margin-right: 10px" :class="{ colors: colorss }"
              >Tokens</span
            >
            <img
              src="../../assets/imgs/downla.png"
              style="width: 17px; height: 17px"
            />
          </div>
          <el-collapse-transition>
            <div class="kuang" v-show="show3">
			  <div @click="goerc('txs20-top')" class="kuangitem" v-if="GLOBAL.erctop.erc20top">
				  <router-link :to='`/txs20-top`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC20 Top Tokens
				  	</div>
				  </router-link>
			  </div>
              <div @click="goerc('txs20')" class="kuangitem" style="border-bottom: #e7eaf3 1px solid;">
				  <router-link :to='`/txs20`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC20 Txs
				  	</div>
				  </router-link>
			  </div>
			  <div @click="goerc('txs721-top')" class="kuangitem" v-if="GLOBAL.erctop.erc721top">
				  <router-link :to='`/txs721-top`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC721 Top Tokens
				  	</div>
				  </router-link>
			  </div>
              <div @click="goerc('txs721')" class="kuangitem" style="border-bottom: #e7eaf3 1px solid;">
				  <router-link :to='`/txs721`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC721 Txs
				  	</div>
				  </router-link>
				  
			  </div>
			  <div @click="goerc('txs1155-top')" class="kuangitem" v-if="GLOBAL.erctop.erc1155top">
				  <router-link :to='`/txs1155-top`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC1155 Top Tokens
				  	</div>
				  </router-link>
			  </div>
              <div @click="goerc('txs1155')" class="kuangitem">
				  <router-link :to='`/txs1155`'>
				  	<div style="color: #8392a5; height: 100%;">
				  		ERC1155 Txs
				  	</div>
				  </router-link>
				  
			  </div>
            </div>
          </el-collapse-transition>
        </div>
        
      </div>
      <div
          class="selectnavd"
          :class="{ bghui: show4 }"
          @click.stop="show4toggle"
        >
          <div>
            <img
              :src="ifhome ? netimg1 : netimg2"
              style="width: 25px; height: 25px"
            />
            <span :class="{ colors: colorss }"> Network </span>
          </div>
          <el-collapse-transition>
            <div class="kuang" v-show="show4">
              <div
                class="kuangitem"
                v-for="(items, index) in pushlist"
                :key="index"
                @click="gotoapi(items.api)"
                :style="
                  hostname == items.api
                    ? 'background-color: #ebebeb;color: #242424;'
                    : ''
                "
              >
                <img
                  :src="items.coin == 'ETM3' ? imgae2 :items.coin == 'ETH'?imgae1 : imgae3"
                  alt=""
                  style="width: 23px"
                />
                <span class="value_span">{{ items.value }}</span>
              </div>
            </div>
          </el-collapse-transition>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
// import { ElStep } from "element-ui/types/step";
import WAV from "wallet-address-validator"
import {Message} from "element-ui"
export default {
  data() {
    return {
      value: "BTC",
      itemvalue: ["BTC", "asd"],
      searchtext: null,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      pushlist: [],
      imgae1: require("../../assets/imgs/ethicon.png"),
      imgae2: require("../../assets/imgs/ETM3.png"),
	  imgae3:require('../../assets/imgs/BTC.png'),
      hostname: "",
      ifhome: true,
      netimg1: require("../../assets/imgs/network.png"),
      netimg2: require("../../assets/imgs/networkd.png"),
	  
	  blocks:[]
    };
  },
  created() {
    this.searchtext = null;
    this.mywindow.onclick = () => {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      
    };
    this.pushlist = this.GLOBAL.option;
	
	this.blocks = this.GLOBAL.blocks
	
    
  },
  mounted() {
    this.searchtext = null;
    this.mywindow.onclick = () => {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
    };
    this.hostname = window.location.hostname;
    if (window.location.pathname == "/") {
      this.ifhome = true;
    } else {
      this.ifhome = false;
    }
  },
  props: ["colorss"],
  methods: {
	  routeras(val){
	  	if(val==="Blocks"){
	  		return "blocks"
	  	}else if(val==="Uncles"){
	  		return 'uncles'
	  	}else if(val==="Forked Blocks(Reorgs)"){
	  		return 'blocks_forked'
	  	}else if(val === "Top Accounts"){
	  		return 'accounts'
	  	}
	  },
	  tranrouter(val){
		  if(val==="Txns"){
		  	return "txs"
		  }else if(val==="Pending"){
		  	return 'txsPending'
		  }else if(val==="Contract Internal"){
		  	return 'contract_internal_txs'
		  }else if(val === "Large Transactions"){
		  	return 'large-txs'
		  }
	  },
    routeraddress() {
	  // 去空格处理
	    this.searchtext = this.searchtext.replace(/^\s*|\s*$/g, "")
		if(/^(1|3)[a-zA-Z\d]|[0-9a-z]{30,64}$/.test(this.searchtext)){
			if(this.searchtext.length<64){
					  let addressif = WAV.validate(this.searchtext,"BTC")
            // console.log(addressif);
					  if(addressif){
              // 地址详情页
						  this.$router.push(`/address/${this.searchtext}`);
					  }else{
						  Message({
						  	message: "Sorry! This is an invalid search string.",
						  	type: "error"
						  })
					  }
			}else if(this.searchtext.length==64 && !(/^[0000][0-9a-z]{63}$/.test(this.searchtext))){
        // 交易详情页
					   this.$router.push(`/hash/${this.searchtext}`);
			}else if(/^[0000][0-9a-z]{63}$/.test(this.searchtext)){
        // 交易详情页
					   this.$router.push(`/block/${this.searchtext}`);
			}else{
					  Message({
					  	message: "Sorry! This is an invalid search string.",
					  	type: "error"
					  })
			}
		}else{
      console.log('2');
      // 区块详情页
			this.$router.push(`/block/${this.searchtext}`);
		}
    },
    handleCommand(command) {
      if (this.$route.meta.currency !== command) {
        this.$store.dispatch("setcurrency", command);
        if (command == "OMNI") {
          this.$router.push("/omnihome");
        } else if (command == "BTC") {
          this.$router.push("/");
        }
      }
    },
    show1toggle() {
      this.show1 = !this.show1;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
    },
    show2toggle() {
      this.show2 = !this.show2;
      this.show1 = false;
      this.show3 = false;
      this.show4 = false;
    },
    show3toggle() {
      this.show3 = !this.show3;
      this.show2 = false;
      this.show1 = false;
      this.show4 = false;
    },
    show4toggle() {
      this.show4 = !this.show4;
      this.show2 = false;
      this.show1 = false;
      this.show3 = false;
    },
    // 路由跳转
    goerc(value) {
      this.$router.push(`/${value}`);
    },
    gotoforkedblocks() {
      this.$router.push("/blocks_forked");
    },
    addyin() {
      document.getElementsByClassName("search_from")[0].style.boxShadow =
        "0px 3px 5px #EFEFEF";
    },
    deleteyin() {
      document.getElementsByClassName("search_from")[0].style.boxShadow =
        "0px 0px 0px #EFEFEF";
    },
    gotoapi(api) {
      if (api == window.location.hostname) {
        return;
      } else {
        const urld = window.location.protocol + "//" + api;
        window.open(urld);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 13.79rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  @media screen and (max-width: 1310px) {
    flex-wrap: wrap;
  }
  justify-content: space-between;
  @media screen and (max-width: 1240px) {
    width: 92%;
  }

  .search_from {
    width: 65% !important;
    height: 0.48rem;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    display: flex;

    @media screen and (max-width: 530px) {
      width: 4.66rem;
      height: 0.57rem;
    }
    @media screen and (max-width: 860px) {
      // margin-top: 15px;
      width: 100% !important;
    }
    .search_input {
      width: 100%;
      height: 0.48rem;
      font-size: 14px;
      color: #404859;
      padding-left: 10px;
      box-sizing: border-box;
      @media screen and (max-width: 530px) {
        height: 0.57rem;
        padding-left: 0.3rem;
      }
    }
    .serach_button {
      cursor: pointer;
      width: 1.13rem;
      height: 0.48rem;
      @media screen and (max-width: 530px) {
        width: 1.13rem;
        height: 0.57rem;
      }
      background-color: #004c96;
      flex-shrink: 0;
      img {
        @media screen and (max-width: 530px) {
          width: 0.32rem;
          height: 0.32rem;
        }
        width: 0.24rem;
        height: 0.24rem;
        margin-top: -0.05rem;
      }
    }
  }
}

.rightselect {
  // width: 70%;

  display: none !important;
  flex-shrink: 0;
  @media screen and (min-width: 860px) {
    display: flex !important;
  }
  justify-content: flex-end;
}
.selectnav {
  padding: 10px 6px 7px;
  position: relative;
  // background-color: red;
  border-radius: 6px;
  margin-left: 14px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.6s;
  .kuang {
    width: 200px;
    font-size: 14px;
    color: #8392a5;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 12px 9px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .kuangitem {
      padding: 0px 6px;
      border-radius: 4px;
      color: #8392a5;
	  // border: 1px solid red;
	  // box-sizing: border-box;
	  height: 42px;
	  line-height: 42px;
    }
    .kuangitem:hover {
      background-color: #ebebeb;
      color: #242424;
    }
  }
}
.selectnavd {
  position: relative;
  // background-color: red;
  border-radius: 6px;
  margin-left: 14px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.6s;
  height: 34px;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(46, 131, 214, 0.39);
  border-radius: 5px;

  .kuang {
    width: 200px;
    font-size: 14px;
    color: #8392a5;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 12px 9px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .kuangitem {
      padding: 14px 6px;
      border-radius: 4px;
      color: #8392a5;
    }
    .kuangitem:hover {
      background-color: #ebebeb;
      color: #242424;
    }
  }
}
.colors {
  color: #dfdfdf !important;
}
.bghui {
  background: rgba(0, 0, 0, 0.1) !important;
  color: #ffffff ;
  box-shadow: 0px 0px 0px !important;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.value_span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 23px;
  opacity: 1;
  margin-left: 10px;
}
.colosss{
	color: #333
}
.colosss:hover{
	color: skyblue
}
</style>
