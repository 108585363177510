<template>
	<div class="blocks">
		<!-- 新头部 -->
		
		
		<indextop></indextop>
		
		
		<div class="header_top">
			<headers></headers>
		</div>
		
		<blockstand :info="info"></blockstand>
		<tranpage :list="list"></tranpage>

		<div style="background-color: #FFFFFF; padding-bottom: 40px;">
			<button type="button" class="load_more" @click="dainji()" v-if="info.transaction_count!=listlength" >
				Load more 
			</button>
		</div>
	</div>
</template>

<script>
	import indextop from "../indextop.vue"
	import headers from "../header/header.vue"
	import {transactionlist} from "../../axios/index.js"
	import blockstand from "./block_stand.vue"
	import tranpage from "../tranpage/tranpage.vue"
	import dropdown from "../dropdown/dropdown.vue"
	export default {
		beforeRouteEnter(to,from,next) {
			console.log(to.params.url)
			
					
			next((vm)=>{
				vm.address=to.params.url
				vm.go()
			})
				
			
			//临时通过
			 // next()
		},
		beforeRouteUpdate(to,from,next) {
			this.address=to.params.url
			this.go()
			next()
		},
		data(){
			return {
				address:null,
				info:{},
				list:{},
				transaction_count:0,
				currentpage:1,
				hide:5,
				listlength:0
			}
		},
		components:{
			blockstand,
			tranpage,
			headers,
			dropdown,
			indextop
		},
		beforeCreate() {
			document.documentElement.scrollTop=0
		},
		methods:{
			async go(page=1,sizes=10){
				if (this.address.length > 30) {
					var res = await transactionlist(`btc/block/?block_hash=${this.address}&size=${sizes}&offset=${(page-1)*5}`)
				}else {
					var res = await transactionlist(`btc/block/?block_number=${this.address}&size=${sizes}&offset=${(page-1)*5}`)
				}
					
					//判断请求是否成功
					
					if(res.data){
						// 币种，地位，确认，其他信息没加
						
						let {hash,number,timestamp,transaction_count,miner,difficulty,send_value,fee,confirmations,size} = res.data
						
						this.transaction_count=Math.ceil(transaction_count/5) 
						
						this.info={hash,number,timestamp,transaction_count,miner,difficulty,send_value,fee,confirmations,size}
						this.info.difficulty=this.toThousands(this.info.difficulty)
						this.info.send_value=this.toThousands(this.info.send_value)
						this.info.size=this.toThousands(this.info.size)
						var dates= new Date(this.info.timestamp*1000)
							var y = dates.getFullYear();    
							var m = dates.getMonth() + 1;    
							m = m < 10 ? ('0' + m) : m;    
							var d = dates.getDate();    
							d = d < 10 ? ('0' + d) : d;
							var h = dates.getHours();  
							h = h < 10 ? ('0' + h) : h;  
							var minute = dates.getMinutes();  
							minute = minute < 10 ? ('0' + minute) : minute; 
							var s = dates.getSeconds();
							s = s < 10 ? ('0' + s) : s; 
						this.info.timestamp=y + '-' + m + '-' + d+' '+h+':'+minute+':'+s
						
						
						// 内容部分
						
						let lists = res.data.transactions.map((item)=>{
							return {
								fee:parseFloat((item.fee).toFixed(8)),
								hash:item.txid,
								block_timestamp:item.block_timestamp,
								output_count:item.output_count,
								output_value:item.output_value,
								inputs:item.inputs.map((inputed)=>{
									return {
										addresses:inputed.address,
										value:parseFloat(parseFloat(inputed.value/100000000).toFixed(8)) ,
									}
								}),
								outputs:item.outputs.map((outputed)=>{
									
									return {
										addresses:outputed.addresses,
										value:parseFloat(parseFloat(outputed.value).toFixed(8)) 
									}
								}),
								total_vout: (item.total_vout).toFixed(8)
								
							}
						})
						
						
						if([res.data.transactions[0].inputs[0].coinbase][0]!==undefined){
							lists[0].inputs.shift()
							lists[0].inputs.unshift({
								addresses:[res.data.transactions[0].inputs[0].coinbase][0]!==undefined?['Coinbase']:[res.data.transactions[0].inputs[0].address[0]],
								value:res.data.transactions[0].total_vout.toFixed(8)
							})
						}
						
						
						
						
						
						
						if(this.list instanceof Array){
							this.list=this.list.concat(lists)
							this.listlength=this.list.length
							console.log(this.listlength)
						}else{
							this.list=lists
							this.listlength=this.list.length
							console.log(this.listlength)
						}
						
						
						
						
						for(let i=0;i<this.list.length;i++){
							var dates= new Date(this.list[i].block_timestamp*1000)
								var y = dates.getFullYear();    
								var m = dates.getMonth() + 1;    
								m = m < 10 ? ('0' + m) : m;    
								var d = dates.getDate();    
								d = d < 10 ? ('0' + d) : d;
								var h = dates.getHours();  
								h = h < 10 ? ('0' + h) : h;  
								var minute = dates.getMinutes();  
								minute = minute < 10 ? ('0' + minute) : minute; 
								var s = dates.getSeconds();
								s = s < 10 ? ('0' + s) : s; 
							this.list[i].block_timestamp=y + '-' + m + '-' + d+' '+h+':'+minute+':'+s
						}
				}
			},
				
			clicks(index){
				if(index==="...") return
				this.currentpage=index
				this.go(this.currentpage)
			},
			pros(){
				if(this.currentpage!=1){
					this.currentpage=this.currentpage-1
					this.go(this.currentpage)
				}
			},
			nexts(){
				if(this.currentpage!=this.transaction_count){
					this.currentpage=this.currentpage+1
					this.go(this.currentpage)
				}
			},
			goback(){
				this.$router.push('/')
			},
			dainji(){
				this.currentpage=this.currentpage+1
				this.go(this.currentpage,10)
			}
		},
		computed:{
			sumpage(){
				
				if(this.currentpage==="...") return
				if(this.currentpage<4){
					return [1,2,3,4,'...',this.transaction_count]
				}else if(this.transaction_count-3>=this.currentpage&&this.currentpage>=4){
					 return [1,'...',this.currentpage-1,this.currentpage,this.currentpage+1,'...',this.transaction_count]
				}else if(this.currentpage>this.transaction_count-3){
					return [1,'...',this.transaction_count-3,this.transaction_count-2,this.transaction_count-1,this.transaction_count]
				}
				
				
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.header_top{
		margin-top: 20px;
	}
	.blocks{
		overflow: hidden;
		background-color: #f7f7f7;
	}
	.pagenav{
		overflow: hidden;

		margin: 10px auto;
		@media screen and (max-width:766px){
			margin: 5px auto;
		}
		li{
			float: left;
			cursor: pointer;
			font-size: 16px;
			font-weight: bold;
			padding: 20px 20px ;
			text-align: center;
			border: 1px solid #EAEAEA;
			@media screen and (max-width:766px){
				padding: 10px 10px;
			}
		}
	}
	
	.gaoliang{
		background-color: #0082FF !important;
		color: white !important;
		border: #0082FF 1px solid !important;
	}
	
	.navtext{
		width: 100%;
		height: 20rem;

		margin-top: 20rem;
		font-size: 16rem;
		color: #242424;
		overflow: hidden;
		div{
			float: left;
			line-height: 20rem;
		}
		.triangle{
			white:0;
			height: 0;
			border-left: 6rem solid #242424;
			border-right: 0rem solid #242424;
			border-top: 7rem solid transparent;
			border-bottom: 7rem solid transparent;
			margin:3rem 6rem;
		}
	}
	.load_more{
		width: 2.2rem;
		height: 0.60rem;
		font-size: 0.2rem;
		border-radius: 8px;
		margin: 0 auto;
		display: block;
		color: #3498E1;
		// font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #3498E1;
		background-color: #FFFFFF;
		cursor: pointer;
	}
	
	// 新头部
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  .zhong{
		  display: flex; width: 92%; align-items: center;
		  @media screen and (max-width:435px){
		  			  width: 99%;
		  }
	  }
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 20px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
</style>
