<template>
	
	<div class="hash_content">
		<div class="hash_con">
			<div class="con_input">
				<div class="input_title">
					Input
					<el-tooltip class="tishi" effect="dark" content="Top Left 提示文字" placement="right-start">
						 <div slot="content">The addresses and <br> amounts specifying<br> the BTC spent.</div>
					      <img  src="../../assets/annotation.png" class="annotation" />
					</el-tooltip>
				</div>
				<!-- 便利 -->
				<div class="input_item" v-for="(item,index) in inhashlists" :key="index">
					<div class="item_id">#{{index}}</div>
					<div class="item_value">
						<div class="value_hash">
							<div v-if="item.txid !== 'coinbase'">
								<router-link class="meiyou" :to="`/address/${item.txid}`">
									{{item.txid}}
								</router-link>
							</div>
							<div v-else style="color:black">
								{{item.txid}}
							</div>
						</div>
						<div class="value_fee">{{item.value}}</div>
					</div>
				</div>
			</div>
			<div class="con_output">
				<div class="output_title">
					Output
					<el-tooltip class="tishi" effect="dark" content="Top Left 提示文字" placement="right-start">
						 <div slot="content">The addresses and<br>amounts specifying <br>BTC received.</div>
					      <img  src="../../assets/annotation.png" class="annotation" />
					</el-tooltip>
				</div>
				<!-- 便利 -->
				<div class="output_item" v-for="(item,index) in outhashlists" :key="index">
					<div class="item_id">#{{index}}</div>
					<div class="item_value">
						<div class="value_hash">
							<div v-if="item.addresses[0] !== 'OP_RETURN'">
								<router-link :to="`/address/${item.addresses[0]}`" style="text-decoration: none">
									{{item.addresses[0]}}
								</router-link>
							</div>
							<div v-else style="color:black">
								{{item.addresses[0]}}
							</div>
						</div>
						<div class="value_fee">{{item.value}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				btnaction:true,
				 visible: false
			}
		},
		props:['inhashlists','outhashlists'],
		methods:{
			chahash(hash){
				this.$router.push(`/hash/${hash}`)
			},
			chaaddress(dizhi){
				this.$router.push(`/address/${dizhi}`)
			}
		}
	}
</script>

<style scoped lang="less">
	.hash_content{
		width: 100%;
		background-color: white;
		margin-top: 20px;
		overflow: hidden;
		.hash_con{
			width: 13.79rem;
			margin: 0.6rem auto;
			display: flex;
			justify-content: space-between;
			@media screen and (max-width:1040px){
				display: block;
			}
			
			@media screen and (max-width:1240px){
				width: 92%;
			}
			.con_input{
				width: 46%;
				@media screen and (max-width:1040px){
					width: 100%;
				}
				.input_title{
					width: 100%;
					font-size: 28px;
					font-weight: bold;
					color: #2B2B2B;
					margin-bottom: 10px;
				}
				.input_item{
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 28px 20px 28px 10px;
					box-sizing: border-box;
					background-color: #EFF5FB;
					.item_id{
						color:#8392A5 ;
						font-size: 14px;
					}
					.item_value{
						width: 92%;
						font-size: 14px;
						display: flex;
						justify-content: space-between;
						@media screen and (max-width:540px){
							display: block;
						}
						.value_hash{
							width: 79%;
							word-wrap: break-word;
							color: #3498E1;
							@media screen and (max-width:540px){
								width: 100%;
							}
						}
						.value_hash:hover{
							text-decoration: none;
							cursor: text;
						}
						.value_hash:nth-child(1){
							text-decoration: none;
						}
						.value_fees{
							width: 10%;
							color: #444444;
							flex-shrink: 0;
						}
					}
				}
			}
		}
				
	}
			.con_output{
				width: 46%;
				@media screen and (max-width:1040px){
					width: 100%;
					margin-top: 20px;
				}
				.output_title{
					width: 100%;
					font-size: 28px;
					font-weight: bold;
					color: #2B2B2B;
					margin-bottom: 10px;
				}
				.output_item{
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 28px 20px 28px 10px;
					box-sizing: border-box;
					background-color: #EFF5FB;
					margin-bottom: 15px;
					.item_id{
						color:#8392A5 ;
						font-size: 14px;
					}
					.item_value{
						width: 94%;
						font-size: 14px;
						display: flex;
						justify-content: space-between;
						@media screen and (max-width:540px){
							display: block;
						}
						.value_hash{
							width: 79%;
							@media screen and (max-width:540px){
								width: 100%;
							}
							word-wrap: break-word;
							color: #3498E1;
						}
						.value_hash:hover{
							text-decoration: none;
							cursor: text;
						}
						.value_fees{
							width: 10%;
							color: #444444;
							flex-shrink: 0;
						}
					
			}
		}
		
	}
	.tishi{
		width: 18px;
		height: 18px;
		margin-left: 0px;
	}
	
	.meiyou{
		color: #0082FF !important;
		text-decoration: none !important;
		cursor: pointer !important;
	}
	.meiyou:hover{
		text-decoration: underline !important;
	}
	.litstext{
		word-wrap: break-word; 
		color: #3498E1; 
		cursor: pointer;
	}
	.litstext:hover{
		text-decoration: underline;
	}
	
	.value_fee{
		@media screen and (max-width:540px){
			margin-top: 8px;
		}
	}
	
</style>
