<template>
	<div>
		<div class="address">
			<div class="address_info">
				<div class="erweima">
					<qriously :value="qrcode" id="erweima" :size="sizes"/>
				</div>
				<ul class="info_list">
					<li>
						<div class="list_name">Address</div>
						<div class="list_value">
							{{address}}<img class="copybtn" :data-clipboard-text="address" @click="copytext" src="../../assets/imgs/ctrlc.png" style="margin-left: 4px; width: 14px; height: 14px;">
						</div>
					</li>
					<li>
						<div class="list_name">Format</div>
						<div class="list_value">BASE58 （P2PKH）</div>
					</li>
					<li>
						<div class="list_name">Transactions</div>
						<div class="list_value">{{infos.transactions}}</div>
					</li>
					<li>
						<div class="list_name">Total Received</div>
						<div class="list_value">{{Decimal(infos.total_received)}}</div>
					</li>
					<li>
						<div class="list_name">Total Sent</div>
						<div class="list_value">{{Decimal(infos.total_send)}}</div>
					</li>
					<li>
						<div class="list_name">Final Balance</div>
						<div class="list_value">{{Decimal(infos.balance)}}</div>
						 <!-- {{peizhi[0].coin}} -->
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import Decimal from "decimal.js"
	import Clipboard from 'clipboard'
	import {Message} from 'element-ui'
	import {addressinfo} from '../../axios/index.js'
	
	export default {
		data(){
			return {
				// xiaobiaoti:"",
				btcinfo:[
					{name:"Activity :",info:"null"},
					{name:"Unconfirmed Txs :",info:"null"},
					{name:"Activity :",info:"null"},
				],
				qrcode:'undefind',
				sizes:230,
				windowidth:document.documentElement.clientWidth,
				infos:{},
				Decimal:function(value){	
				   return new Decimal(value).div(new Decimal(100000000))
				}
			}
		},
		props:['address','info'],
		methods:{
			copytext(){
				var clipboard = new Clipboard('.copybtn');
				clipboard.on('success', e => {
					console.log(e)
					Message({
						message: `Copied ${e.text}`,
						type: 'success'
					});
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					// 不支持复制
					Message({
						message: '该浏览器不支持自动复制',
						type: 'warning'
					});
				// 释放内存
				clipboard.destroy()
				})
			},
			goback(){
				this.$router.push('/')
			},
			addressqing(){
				let address = this.$route.params.url
				let res = addressinfo(address);
				console.log(res);
				res.then((datas)=>{
					this.infos={
						balance:datas.balance,
						transactions:datas.total_transactions,
						total_send:datas.total_send,
						total_received:datas.total_received
					}
					console.log(this.infos)
				})		
			}
		},
		watch:{
			address:function(newval,oldval){
				this.qrcode=newval
			}
		},
		beforeCreate() {
			
			
		},
		// data里面的数据挂完之后
		created() {
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
			this.addressqing()
		}
	}
</script>

<style lang="less" scoped>
	.address{
		width: 13.79rem;
		margin: 0px auto 60px;
		@media screen and (max-width:1240px){
			width: 92%;
		}
		@media screen and (max-width:1110px){
			margin: 0px auto;
		}
	}
	.nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 1379px;
		margin: 14px auto;
		display: flex;
		align-items: center;
		@media screen and (max-width:1400px) {
			width: 100%;
		}
	}
	.triangle{
		width:0;
		height: 0;
		border-left: 6px solid #242424;
		border-right: 0rem solid #242424;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		margin:3px 6px;
	}
	.address_info{
		width: 100%;
		background-color: #FFFFFF;
		padding: 40px 5px;
		border-radius: 9px;
		overflow: hidden;
		box-sizing: border-box;
		display: flex;
		justify-content: space-around;
		align-items: center;
		@media screen and (max-width:770px){
			display: block;
		}
		.erweima{
			padding: 8px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 12px;
			border: 1px solid #EFEFEF;
			@media screen and (max-width:770px){
				width: 280px;
				height: 280px;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}
		.info_list{
			width: 70%;
			padding-left: 10px;
			@media screen and (max-width:770px){
				width: 100%;
			}
			li{
				width: 100%;
				display: flex;
				padding: 15px 0;
				font-size: 14px;
				border-bottom: 1px solid #DFDFDF;
				.list_name{
					width: 30%;
					@media screen and (max-width:770px){
						padding-left: 10px;
						
					}
				}
				.list_value{
					width: 70%;
					@media screen and (max-width:770px){
						text-align: right;
						padding-right: 30px;
						word-wrap: break-word;
						box-sizing: border-box;
					}
				}
			}
			li:last-child{
				
			}
		}
	}
	
</style>
