
import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router/router.js'
import axios from "axios"
import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import VueQriously from 'vue-qriously'
// 公共样式表
import "../public/reset.css"
import global from "./global.js"
Vue.prototype.GLOBAL = global
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(VueQriously)
Vue.prototype.mywindow=window
Vue.prototype.toThousands =  (num = 0) => {
							   return num.toString().replace(/\d+/, function(n) {
								  return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
							   });
							}

Vue.prototype.peizhi = global.option.filter(item=>{
	if(window.location.host==item.api){
		return item
	}
})

console.log(Vue.prototype.peizhi)



new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')

// 进入页面时就显示home路由组件
