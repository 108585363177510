<template>
	<div>
		<div class="blocks hides">
			<div>
				<div class="nav_title">
					<div class="hands" @click="goback">{{xiaobiaoti}}</div>
					<div class="triangle" ></div>
					<div >
						Block {{toThousands(info.number)}}
					</div>
				</div>
				<div class="block_info">
					<ul>
						<li>
							<div class="block_name">
								<el-tooltip class="item" effect="dark" content="Unique identifier used to identify a particular block" placement="right">
									 <span>Hash:</span>
								</el-tooltip>
							</div>
							<div class="block_value" style="overflow: hidden;text-overflow: ellipsis; padding-right: 10px; box-sizing: border-box; display: flex; align-items: center;">
								<div style="overflow: hidden; text-overflow: ellipsis;">
									{{info.hash}}
								</div>
								
								<img @click="copytext" :data-clipboard-text="info.hash" src="../../assets/imgs/ctrlc.png" style="width: 14px; height: 14px; flex-shrink: 0; margin-left: 4px;" class="copybtn" />
							</div>
						</li>
						<li>
							<div class="block_name">
								<el-tooltip class="item" effect="dark" content="Number of blocks connected on the blockchain" placement="right">
									 <span>Block:</span>
								</el-tooltip>
							</div>
							<div class="block_value">{{toThousands(info.number)}}</div>
						</li>
						<li>
							<div class="block_name">Coin：</div>
							<div class="block_value">Bitcoin (BTC)</div>
						</li>
						<li>
							<div class="block_name">Time：</div>
							<div class="block_value">{{info.timestamp}}</div>
						</li>
						<li>
							<div class="block_name">Status:</div>
							<div class="block_value">{{info.confirmations}} Confirmations</div>
						</li>
						<li>
							<div class="block_name">
								<el-tooltip class="item" effect="dark" content="Number of transactions included in this block" placement="right">
									 <span>Transactions:</span>
								</el-tooltip>
							</div>
							<div class="block_value">{{info.transaction_count}}</div>
						</li>
						<li>
							<div class="block_name">
								<el-tooltip class="item" effect="dark" content="Who confirmed the transactions in the block" placement="right">
									 <span>Miner:</span>
								</el-tooltip>
							</div>
							<div class="block_value">{{info.miner}}</div>
						</li>
						<li>
							<div class="block_name">
								<el-tooltip class="item" effect="dark" content="Mathematical value of how hard it is to find a valid hash for this block" placement="right">
									 <span>Difficulty:</span>
								</el-tooltip>
							</div>
							<div class="block_value">{{info.difficulty}}</div>
						</li>
						<li>
							<div class="block_name">Other Info:</div>
							<div class="block_value">
								Size: {{info.size}} bytes, <a :href="`${lainjie}/api/btc/block_raw/?block_number=${info.number}`" class="undelines" target="_blank">Raw Data</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 手机端 -->
		<div class="shows shouji">
			<div class="shouji_nav_title" style=" margin: 14px auto;">
				<div class="hands" @click="goback()" >Bitcoin Explorer</div>
				<div class="triangle" ></div>
				<div >
					{{toThousands(info.number)}}
				</div>
			</div>
			<div class="s_info" style="border-radius: 9px; padding-top: 15px;font-size: 16px;">
				<div class="info_item" style="height: 60px; display: flex; flex-direction: column; justify-content: center; padding-bottom: 15px;">
					<div class="item_name" style="margin-bottom: 4px;;">
						<el-tooltip class="item" effect="dark" content="Unique identifier used to identify a particular block" placement="right">
							 <span>Hash:</span>
						</el-tooltip>
					</div>
					<div style="word-wrap: break-word;">
						{{info.hash}}
						<img @click="copytext" :data-clipboard-text="info.hash" src="../../assets/imgs/ctrlc.png" style="width: 14px; height: 14px; flex-shrink: 0; margin-left: 4px;" class="copybtn" />
					</div>
				</div>
				<div  style="height: 60px; border-bottom: 1px solid #EFEFEF ; padding-left: 10px; padding-top: 12px; box-sizing: border-box;">
					<div style="margin-bottom: 4px;">
						<el-tooltip class="item" effect="dark" content="Number of blocks connected on the blockchain" placement="right">
							 <span>Block:</span>
						</el-tooltip>
					</div>
					<div>{{toThousands(info.number)}}</div>
				</div>
				<div class="info_item" style="height: 60px;display: flex; flex-direction: column; justify-content: center;">
					<div style="margin-bottom: 4px;">Coin：</div>
					<div>Bitcoin (BTC)</div>
				</div>
				<div class="info_item">
					<div>Time：</div>
					<div class="item_name">{{info.timestamp}}</div>
				</div>
				<div class="info_item">
					<div>Status：</div>
					<div class="item_name">{{info.confirmations}} Confirmations</div>
				</div>
				<div class="info_item">
					<div>
						<el-tooltip class="item" effect="dark" content="Number of transactions included in this block" placement="right">
							 <span>Transactions:</span>
						</el-tooltip>
					</div>
					<div class="item_name">{{info.transaction_count}}</div>
				</div>
				<div class="info_item" style="word-wrap: break-word;">
					<div >
						<el-tooltip class="item" effect="dark" content="Who confirmed the transactions in the block" placement="right">
							 <span>Miner:</span>
						</el-tooltip>
					</div>
					<div class="item_name">{{info.miner}}</div>
				</div>
				<div class="info_item">
					<div>
						<el-tooltip class="item" effect="dark" content="Mathematical value of how hard it is to find a valid hash for this block" placement="right">
							 <span>Difficulty:</span>
						</el-tooltip>
					</div>
					<div class="item_name">{{info.difficulty}}</div>
				</div>
				<div class="info_item" style="margin-bottom: 20px;">
					<div>Other Info:</div>
					<div class="item_name">
						Size: {{info.size}} bytes, <a :href="`${lainjie}/api/btc/block_raw/?block_number=`+info.number" class="undelines" target="_blank">Raw Data</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Clipboard from 'clipboard'
	import {Message} from 'element-ui'
	export default {
		props:['info'],
		data(){
			return {
				lainjie:window.location.origin,
				xiaobiaoti:""
			}
		},
		mounted() {
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
		},
		methods:{
			goback(){
				this.$router.push('/')
			},
			copytext(){
				var clipboard = new Clipboard('.copybtn');
				clipboard.on('success', e => {
					console.log(e)
					Message({
						message: `Copied ${e.text}`,
						type: 'success'
					});
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					// 不支持复制
					Message({
						message: '该浏览器不支持自动复制',
						type: 'warning'
					});
				// 释放内存
				clipboard.destroy()
				})
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.blocks{
		width: 13.79rem;
		margin: 0px auto 60px;
		@media screen and (max-width:1240px){
			width: 92%;
		}
	}
	// 小导航
	.nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 13.79rem;
		margin: 14px auto;
		display: flex;
		align-items: center;
		
		@media screen and (max-width:1400px) {
			width: 100%;
		}
		
	}
	.shouji_nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 13.79rem;
		margin: 14px auto;
		display: flex;
		align-items: center;
		@media screen and (max-width:700px){
			width: 92%;
		}
	}
	.triangle{
		white:0;
		height: 0;
		border-left: 6px solid #242424;
		border-right: 0rem solid #242424;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		margin:3px 6px;
	}
	
	.block_info{
		width: 13.79rem;
		margin: 0 auto;
		border-radius: 9px;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		display: flex;
		color: #242424;
		// 临时
		@media screen and (max-width:1240px){
			width: 100%;
		}
		ul{
			width: 100%;
			li{
				display: flex;
				justify-content: space-between;
				font-size: 15px;
				line-height: 60px;
				border-bottom: 1px solid #DFDFDF ;
				.block_name{
					width: 300px;
					// border: 1px solid red;
					padding-left: 33px;
					box-sizing: border-box;
					flex-shrink: 0;
					@media screen and (max-width:1024px) {
						flex-shrink: 1;
					}
					
				}
				.block_value{
					width: 1030px;
				}
			}
		}
		
	}
	// 手机端
	.s_info{
		width: 92%;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		margin: 0 auto;
		background-color: #FFFFFF;
		
	}
	.info_item{
		height: 70px;
		display: flex; 
		border-bottom: 1px solid #EFEFEF;
		flex-direction: column; 
		 padding: 0 10px;
		 box-sizing: border-box;
		justify-content: center;
			.item_name{
				margin-top: 6px;
			}
	}
	
	.undelines:hover{
			 cursor: pointer;
			 text-decoration: underline;
	}
	

	.hides{
		@media screen and (max-width:700px){
			display: none;
		}
	}
	.shows{
		@media screen and (max-width:700px){
			display: block !important;
		}
	}
	.shouji{
		display: none;
	}
	
	
	
	
	
	
	
	
	.item{
		cursor: pointer;
	}
	
</style>
