<template>
	<div class="address">
		<!-- 新头部 -->
		<indextop></indextop>
		
		<div style="margin-top: 26px;">
			<headers></headers>
		</div>
		<div class="nav_title">
				<div class="hands" @click="goback">{{xiaobiaoti}}</div>
				<div class="triangle"></div>
				<div>
					Address
				</div>
			</div>
		<addressstand :address="address" :info="info"></addressstand>
		<tranpage :list="list"></tranpage>
		
		<div style="background-color: #FFFFFF; padding-bottom: 40px;">
			<button type="button" class="load_more" @click="go(address,1,morehash,10)" v-if="btnstate<5?false:true">
				Load more 
			</button>
		</div>
	</div>
</template>

<script>
	import indextop from "../indextop.vue"
	import dropdown from '../dropdown/dropdown.vue'
	import headers from "../header/header.vue"
	import address_stand from "../address_stand/address_stand.vue"
	import tranpage from "../tranpage/tranpage.vue"
	import {transactionlist} from "../../axios/index.js"
	export default {
		components:{
			indextop,
			addressstand:address_stand,
			tranpage,
			headers,
			dropdown
		},
		data(){
			return {
				address:null,
				info:{},
				list:[],
				xiaobiaoti:"",
				// 页码
				pages:[1,2,3,4,'...',10],
				// 当前页码
				currentpage:1,
				// 总页数
				transaction_count:0,
				meiyeyeshu:5,
				morehash:"",
				btnstate:5
			}
		},
		mounted() {
			// 滚动条居上
			document.documentElement.scrollTop=0
		},
		computed:{
			sumpage(){
				if(this.currentpage==="...") return
				if(this.currentpage<4){
					return [1,2,3,4,'...',this.transaction_count]
				}else if(this.transaction_count-3>=this.currentpage&&this.currentpage>=4){
					 return [1,'...',this.currentpage-1,this.currentpage,this.currentpage+1,'...',this.transaction_count]
				}else if(this.currentpage>this.transaction_count-3){
					return [1,'...',this.transaction_count-3,this.transaction_count-2,this.transaction_count-1,this.transaction_count]
				}
			}
		},
		beforeRouteEnter(to, from, next){
			// 请求成功调换 不成功不跳转
		      
		   next((vm)=>{
				// next()的参数是一个回调函数，当组件创建完成后调用，回调的函数就是组件对象this
				vm.address=to.params.url
				vm.go(vm.address)
			})
			   
		   
		   
		   // 临时通过
		   // next()
		   
		},
		beforeRouteUpdate (to, from, next) {
		    // 在当前路由改变，但是该组件被复用时调用
		    // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
		    // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
		    // 可以访问组件实例 `this`
			this.address=to.params.url
			this.go(this.address)
			next()
		},
		methods:{
			// 请求页数
			async go(url, pinjie=0,hash="",sizes=10){
				this.list = []
				let res = await transactionlist(`btc/address/?address=${url}&size=${sizes}&hash=${hash}`)
				console.log(res)
				this.transaction_count=Math.ceil(res.count/this.meiyeyeshu)
				//判断请求是否成功
				if(res.data){
					let {balance,total_received,total_send,total_transactions,type} = res.data
					this.info={
						balance,
						total_received,
						total_send,
						total_transactions,
						type
					}
					this.info.total_received=this.info.total_received/100000000
					this.info.balance=this.info.balance/100000000
					this.info.total_received=this.toThousands(this.info.total_received)
					
					let lists = res.data.txs.map((item)=>{
						return {
							fee:parseFloat(item.fee.toFixed(8)),
							hash:item.hash,
							timestamp:item.timestamp,
							block_timestamp:item.block_timestamp,
							output_count:item.confirmations,
							output_value:item.fee,
							total_vout:item.total_vout/100000000,
							inputs:item.inputs.map((inputed)=>{
								return {
									addresses:inputed.addresses,
									value:parseFloat((inputed.value/100000000).toFixed(8)) ,
								}
							}),
							outputs:item.outputs.map((outputed)=>{
								return {
									addresses:outputed.addresses,
									value:parseFloat((outputed.value/100000000).toFixed(8)) 
								}
							})
						}

					})
					console.log(lists.length)
					this.btnstate = lists.length
					this.morehash=lists.slice(-1)[0].hash
						
					
					
					
					for(let i=0;i<lists.length;i++){
						var dates= new Date(lists[i].timestamp*1000)
						var y = dates.getFullYear();    
						var m = dates.getMonth() + 1;    
						m = m < 10 ? ('0' + m) : m;    
						var d = dates.getDate();    
						d = d < 10 ? ('0' + d) : d;
						var h = dates.getHours();  
						h = h < 10 ? ('0' + h) : h;  
						var minute = dates.getMinutes();  
						minute = minute < 10 ? ('0' + minute) : minute;
						var s = dates.getSeconds();
						s = s < 10 ? ('0' + s) : s;
						lists[i].timestamp=y + '-' + m + '-' + d+' '+h+':'+minute+":"+s
					}
					// 拼接
					if(pinjie==1){
						this.list=this.list.concat(lists) 
						console.log(res.data)
						console.log(this.list)
					}else{
						this.list=lists
					}
					
				}
				
				
				
				
			},
			// 点击数组按钮时
			numberbtn(index){
				if(index==="...") return
				
				if(index<4){
					this.pages=[1,2,3,4,'...',10]
								
					this.go(this.address,index)
				}else if(8>index&&index>=4){
					this.pages=[1,'...',(index-1),index,(index+1),'...',10]
					this.go(this.address,index)
				}else if(index>=8){
					this.pages=[1,'...',7,8,9,10]
					this.go(this.address,index)
				}
			},
			pros(){
				if(this.currentpage!=1){
					this.currentpage=this.currentpage-1
					this.go(this.address,this.currentpage)
				}
			},
			nexts(){
				if(this.currentpage!=this.transaction_count){
					this.currentpage=this.currentpage+1
					this.go(this.address,this.currentpage)
				}
			},
			clicks(index){
				if(index==="...") return
				this.currentpage=index
				console.log(this.currentpage)
				this.go(this.address,this.currentpage)
			},
			goback(){
				this.$router.push('/')
			}
		},
		// data里面的数据挂完之后
		created() {
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
			this.addressqing()
		}
	}
</script>

<style lang="less" scoped>
	.address{
		background-color: #F7F7F7;
		overflow: hidden;
	}
	.pagenav{
		overflow: hidden;
		width: -webkit-fit-content;
		margin: 10rem auto;
		background-color: #FFFFFF;
		@media screen and (max-width:766px){
			margin: 5rem auto;
		}
		li{
			float: left;
			cursor: pointer;
			font-size: 16rem;
			font-weight: bold;
			padding: 20rem 20rem ;
			text-align: center; 
			border: 1px solid #EAEAEA;
			@media screen and (max-width:766px){
				padding: 10rem 10rem;
			}
		}
	}
	.load_more{
		width: 2.2rem;
		height: 0.60rem;
		font-size: 0.2rem;
		border-radius: 8px;
		margin: 0 auto;
		display: block;
		color: #3498E1;
		// font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #3498E1;
		background-color: #FFFFFF;
		cursor: pointer;
	}
	
	.navtext{
		width: 100%;
		height: 20rem;
		
		margin-top: 20rem;
		font-size: 16rem;
		color: #242424;
		overflow: hidden;
		div{
			float: left;
			line-height: 20rem;
		}
		.triangle{
			white:0;
			height: 0;
			border-left: 6rem solid #242424;
			border-right: 0rem solid #242424;
			border-top: 7rem solid transparent;
			border-bottom: 7rem solid transparent;
			margin:3rem 6rem;
		}
	}
	
	.gaoliang{
		background-color: #0082FF !important;
		color: white !important;
		border: #0082FF 1px solid !important;
	}
	// 新头部
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  .zhong{
		  display: flex; width: 92%; align-items: center;
		  @media screen and (max-width:435px){
		  			  width: 99%;
		  }
	  }
	}
	.triangle{
		white:0;
		height: 0;
		border-left: 6px solid #242424;
		border-right: 0rem solid #242424;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		margin:3px 6px;
	}
	.nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 13.79rem;
		margin: 14px auto;
		display: flex;
		align-items: center;
		
		@media screen and (max-width:1400px) {
			width: 100%;
			margin: 14px 0.3rem auto;
		}
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 20px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
</style>
