import Vue from "vue"
import VueRouter from 'vue-router'
import home from "../components/home/home.vue"
import address from "../components/address/address.vue"
import hash from "../components/hash/hash.vue"
import block from "../components/block/block.vue"
import transaction from "../components/transaction/transaction.vue"

// omni组件
import omnihome from "../omni/ominhome/ominhome.vue"
import omniproperty from "../omni/omniProperty/omniProperty.vue"
import untransactions from "../components/untransactions/untransactions.vue"


// block下拉
import blocks from "../views/blocks.vue"
import pendings from "../views/pending.vue"

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}



Vue.use(VueRouter);

const router =  new VueRouter({
	routes:[
		{
			name:"home",
			path:"/",
			component:home,
			meta:{currency:"BTC"}
		},
		{
			name:"untransactions",
			path:"/untransactions",
			component:untransactions,
			meta:{currency:"BTC",types:"address"}
		}
		,
		{
			name:"address",
			path:"/address/:url",
			component:address,
			meta:{currency:"BTC",types:"address"}
		},
		{
			name:"hash",
			path:"/hash/:url",
			component:hash,
			meta:{currency:"BTC"}
		},
		{
			name:"block",
			path:"/block/:url",
			component:block,
			meta:{currency:"BTC"}	
		},
		// omni路由
		{
			name:"omnihome",
			path:"/omnihome",
			component:omnihome,
			meta:{currency:'OMNI'}
		},
		{
			name:"omniproperty",
			path:"/omniproperty/:searchtext",
			component:omniproperty,
			meta:{currency:'OMNI'}
		},
		{
			path:'/blocks',
			component:blocks
		},
		{
			path:'/pending',
			component:pendings
		}
		
	],
	mode:'history'
})

// 相同路由不跳转
let push=VueRouter.prototype.push;
let replace=VueRouter.prototype.replace;


export default router