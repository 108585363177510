<template>
	<div class="footer">
		
		<div class="follow">
			<a class="item" :href="item.address" target="_blank" v-for="(item,index) in footerpc">
				<img :src="`${item.imguel}`" style="width: 25px; height: 25px; margin-right: 3px;" />
				<p style="margin-left: 3px;">{{item.name}}</p>
			</a>
		</div>
		
		<div class="shouji_follow">
			<div class="rows">
				<a class="top_item" :href="item.address" target="_blank" v-for="(item,index) in footershou1">
					<img :src="item.imguel" style="width: 20px; height: 20px; margin-right: 2px;" />
					<p style="margin-left: 2px;">{{item.name}}</p>
				</a>
			</div>
			<div class="rows">
				<a class="top_item" :href="item.address"  target="_blank"  v-for="(item,index) in footershou2">
					<img :src="item.imguel" style="width: 20px; height: 20px; margin-right: 2px;" />
					<p style="margin-left: 2px;">{{item.name}}</p>
				</a>
				
			</div>
		</div>
		
		<div class="bottom_text">
			 Copyright  ©  2022  {{GLOBAL.brand}}
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				titles:'',
				githubimg:require('../../assets/imgs/github.png') ,
				twitterimg:require('../../assets/imgs/twitter1.png') ,
				telegramimg:require('../../assets/imgs/telegram1.png') ,
				discord: require('../../assets/imgs/discord.png'),
				footerpc:[],
				footershou1:[],
				footershou2:[],
				that:this
			}
		},
		created(){
			
			this.footerpc = this.GLOBAL.footers.map(item=>{
				if(item.name=="Github"){
					item.imguel = this.githubimg
				}else if(item.name=="Twitter"){
					item.imguel = this.twitterimg
				}else if(item.name=="Telegram"){
					item.imguel =this.telegramimg
				}else if(item.name == "Discord"){
					item.imguel = this.discord
				}
				return item
			})
			let one = []
			this.GLOBAL.footers.length
			for(let i= 0;i<2;i++){
				one.push(this.GLOBAL.footers[i])
			}
			

			this.footershou1 = one.map(item=>{
				if(item.name=="Github"){
					item.imguel = this.githubimg
				}else if(item.name=="Twitter"){
					item.imguel = this.twitterimg
				}else if(item.name=="Telegram"){
					item.imguel =this.telegramimg
				}else if(item.name == "Discord"){
					item.imguel = this.discord
				}
				return item
			})

			
			let two=[]
			for(let i= 2;i<this.GLOBAL.footers.length;i++){
				two.push(this.GLOBAL.footers[i])
			}
		
			this.footershou2 = two.map(item=>{
				if(item.name=="Github"){
					item.imguel = this.githubimg
				}else if(item.name=="Twitter"){
					item.imguel = this.twitterimg
				}else if(item.name=="Telegram"){
					item.imguel =this.telegramimg
				}else if(item.name == "Discord"){
					item.imguel = this.discord
				}
				return item
			})
		}
	}
</script>

<style lang="less" scoped>
	.footer{
		width: 100%;
		height: 203px;
		background: url(../..//assets/imgs/footerbanner.png) no-repeat;
		overflow: hidden;
		h3{
			font-size:0.2rem;
			text-align: center;
			color: white;
			font-weight: bold;
			margin: 18px 0;
		}
		.follow{
			width: 623px;
			height: 60px;
			margin: 0 auto;
			display: none;
			justify-content: center;
			align-items: center;
			margin-top: 49px;
			@media screen and (min-width:800px){
				display: flex;
			}
			.item{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 10px;
				width: 1.37rem;
				height: 0.5rem;
				border-radius: 6px;
				background-color: #F6F6F6;
				font-size: 15px;
				font-weight: bold;
				color: #242424;
				box-sizing: border-box;
				cursor: pointer;
			}
			.item:hover{
				background-color: #C4C4C4;
			}
			.item:active{
				background-color: #C4C4C4;
				border: 1px solid #707070;
			}
		}
		
		.shouji_follow{
			width: 250px;
			height: 87px;
			display: none;
			margin: 0 auto;
			margin-top: 40px;
			@media screen and (max-width:800px){
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			.rows{
				width: 100%;
				display: flex;
				height: 38px;
				justify-content: space-between;
				padding: 0 8px;
				box-sizing: border-box;
				.top_item{
					display: flex;
					align-items: center;
					box-sizing: border-box;
					justify-content: center;
					width: 104px;
					height: 38px;
					margin-right: 10px;
					background-color: #F6F6F6;
					border-radius: 6px;
					font-size: 14px;
					color: #242424;
					font-weight: bold;
					cursor: pointer;
				}
				.top_item:active{
					background-color:#707070 ;
					border: 1px solid #707070;
				}
			}
		}
		.bottom_text{
			color: #D6D6D6;
			font-size: 0.16rem;
			margin-top: 40px;
			text-align: center;
			@media screen and (max-width:1671px){
				font-size: 14px;
			}
		}
		
	}
	
	
</style>
