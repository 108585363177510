<template>
	<div class="hash">
		<!-- 新头部 -->
		
		<indextop></indextop>
		
		<div style="margin-top: 25px;">
			<headers></headers>
		</div>
		<hashstand :hashinfo="hashinfo"></hashstand>
		<hashcontent :inhashlists="inhashlists" :outhashlists="outhashlists"></hashcontent>
	</div>
</template>

<script>
	import indextop from "../indextop.vue"
	import dropdown from "../dropdown/dropdown.vue"
	import headers from "../header/header.vue"
	import hashstand from "../hash_stand/hash_stand.vue"
	import hashcontent from "../hash_content/hash_content.vue"
	import {transactionlist} from "../../axios/index.js"
	export default {
		components:{
			indextop,
			hashstand,
			hashcontent,
			headers,
			dropdown
		},
		data(){
			return {
				hashinfo:{},
				list:[],
				hash:"",
				inhashlists:[],
				outhashlists:[],
				address:null
			}
		},
		beforeRouteEnter(to, from, next){
			// 请求成功调换 不成功不跳转
			console.log('正在查hash')
		   transactionlist(`btc/transactions/?hash=${to.params.url}`).then((res)=>{
			   if(res.data){
				   next((vm)=>{
				   				// next()的参数是一个回调函数，当组件创建完成后调用，回调的函数就是组件对象this
				   				vm.address=to.params.url
								vm.go(vm.address)
				   			})
			   }else{
				   console.log("查询失败")
			   }
			   
		   })
		   
		  
		},
		beforeRouteUpdate (to, from, next) {
		    // 在当前路由改变，但是该组件被复用时调用
		    // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
		    // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
		    // 可以访问组件实例 `this`
			
			this.address=to.params.url
			this.go(this.address)
			next()
		},
		methods:{
			async go(url,page=1){
				let res = await transactionlist(`btc/transactions/?hash=${url}`)
				// console.log("aaa");
				//判断请求是否成功
				if(res.data){
					
					let {txid,block_number,blocktime,size,weight,confirmations,total_vin,total_vout,fee,vsize} = res.data
					// hash_stand数据
					this.hashinfo={
						txid,
						block_number,
						blocktime,
						size,
						weight,
						confirmations,
						total_vin,
						total_vout,
						fee,
						vsize
					}
					
					// 时间格式化
					var dates= new Date(this.hashinfo.blocktime*1000)
					var y = dates.getFullYear();    
					var m = dates.getMonth() + 1;    
					m = m < 10 ? ('0' + m) : m;    
					var d = dates.getDate();    
					d = d < 10 ? ('0' + d) : d;
					var h = dates.getHours();  
					h = h < 10 ? ('0' + h) : h;  
					var minute = dates.getMinutes();  
					minute = minute < 10 ? ('0' + minute) : minute;  
					var s = dates.getSeconds();
					 s = s <10 ? ('0'+s) : s
					this.hashinfo.blocktime=y + '-' + m + '-' + d+' '+h+':'+minute+":"+s
					
					let inhashlist= res.data.vin.map((item)=>{

						return {
							address:item.address,
							// asm:item.asm.split(" "),
							asm:item.asm,
							hex:item.hex,
							value:parseFloat((item.value/100000000).toFixed(8)) ,
							scriptSig:item.scriptSig,
							txinwitness:item.txinwitness,
							txid:item.address.length===0?'coinbase':item.address
						}
					})
					console.log(res.data.vin)
					
					
					console.log(inhashlist[0])
					
					let outhashlist= res.data.vout.map((item)=>{
						return {
							addresses:item.addresses.map((xx)=>{
										return xx
							}),
							value:parseFloat((item.value/100000000).toFixed(8)) ,
							asm:item.scriptPubKey.asm.split(" ").sort(),
							hex:item.scriptPubKey.hex
						}
						
						 
						
					})
					
					this.inhashlists=inhashlist
					this.outhashlists=outhashlist
				}
			},
			goback(){
				this.$router.push('/')
			}
		},
		mounted() {
			document.documentElement.scrollTop=0
			console.log(this.$route.meta.currency)
		}
	}
</script>

<style lang="less" scoped>
	.hash{
		background-color:#F7F7F7 ;
		overflow: hidden;
		width: 100%;
	}
	.navtext{
		width: 400rem;
		height: 20rem;
		margin-top: 20rem;
		font-size: 16rem;
		color: #242424;
		overflow: hidden;
		@media screen and (max-width:766px){
			margin-left: 5rem;
		}
		div{
			float: left;
			line-height: 20rem;
		}
		.triangle{
			white:0;
			height: 0;
			border-left: 6rem solid #242424;
			border-right: 0rem solid #242424;
			border-top: 7rem solid transparent;
			border-bottom: 7rem solid transparent;
			margin:3rem 6rem;
		}
	}
	
	// 新头部
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  .zhong{
		  display: flex; width: 92%; align-items: center;
		  @media screen and (max-width:435px){
		  			  width: 99%;
		  }
	  }
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 20px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
</style>
