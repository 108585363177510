<template>
  <div>
    <div class="info">
      <div class="nav_title">
        <div class="lefttitle">
          <div @click="gohome" style="cursor: pointer">{{xiaobiaoti}}</div>
        </div>
      </div>
      
      <div class="info_con">
        <div class="con_left">
          <ul class="left_con">
            <li>
              <div style="margin-right: 10px" class="hideandshow">
                <img
                  src="../../assets/imgs/mapone.png"
                  style="width: 0.4rem; height: 0.4rem"
                />
              </div>
              <div>
                <div class="huise" style="font-size: 14px; margin-bottom: 7px">
                  Activity:
                </div>
                <div style="font-size: 14px; font-weight: bold">
                  {{info.activity}}
                </div>
              </div>
            </li>
            <li>
              <div style="margin-right: 10px" class="hideandshow">
                <img
                  src="../../assets/imgs/maptwo.png"
                  style="width: 0.4rem; height: 0.4rem"
                />
              </div>
              <div>
                <div class="huise" style="font-size: 14px; margin-bottom: 7px">
                  Unconfirmed Txs:
                </div>
                <div style="font-size: 14px; font-weight: bold">
                  {{info.unconfirmed_txs}}
                </div>
              </div>
            </li>
            <li>
              <div style="margin-right: 10px" class="hideandshow">
                <img
                  src="../../assets/imgs/mapthree.png"
                  style="width: 0.4rem; height: 0.4rem"
                />
              </div>
              <div>
                <div class="huise" style="font-size: 14px; margin-bottom: 7px">
                  Fee Rate:
                </div>
                <div style="font-size: 14px; font-weight: bold">
                  {{info.fee_rate}}
                </div>
              </div>
            </li>
            <li>
              <div style="margin-right: 10px" class="hideandshow">
                <img
                  src="../../assets/imgs/mapfour.png"
                  style="width: 0.4rem; height: 0.4rem"
                />
              </div>
              <div>
                <div class="huise" style="font-size: 14px; margin-bottom: 7px">
                  Average block time:
                </div>
                <div style="font-size: 14px; font-weight: bold">
					{{info.average}}
                </div>
              </div>
            </li>
          </ul>
          <div class="leftfenge"></div>
        </div>
        <div class="fenge"></div>
        <div class="con_right">
          <div
            style="
              width: 100%;
              font-size: 14px;
              color: #8392a5;
              text-align: center;
              font-weight: bold;"
          >
			Bitcoin Transaction History In {{daynumber}} Days
          </div>
		  
          <div style="width: 100%px; height: 323px" id="echart-box">
			  
		  </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import * as echarts from 'echarts'
	import {maps} from "../../axios/index/api.js"
	import {
		transactionlist
	} from '../../axios/index.js'
	import {
		omnilist
	} from '../../axios/omniaxios/index.js'
import { getCipherInfo } from '../../axios/index/api.js'
	export default {
		data() {
			return {
				xiaobiaoti:"",
				leftinfo:{},
				info:{},
				option: {
					title: {
						text: "",
						left: 'center',
						textStyle: {
							fontSize: '14', //更改坐标轴文字大小
							color: "#8392A5"
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					grid: {
						top: "10%",
						left: "3%",
						right: "0%",
						bottom: "5%",
						containLabel: true
					},
					toolbox: {
						// 另存为图片
				  // feature: {
						//   saveAsImage: {}
				 	// }
					},
					xAxis: {
						type: 'category',
						boundaryGap: true,
						// x轴的数据
						data: [],
						inverse: true,
						axisLabel: {
							show: true,
							textStyle: {
								//更改坐标轴文字颜色
								fontSize: '12' //更改坐标轴文字大小
							},
							interval: 1,
							rotate: 40
						}
					},
					yAxis: {
						type: 'value',
						data:[]
					},
					series: [{
						name: 'Transactions',
						type: 'line',
						stack: 'Total',
						// y轴的数据
						data: []
					}]
				},
				urls: this.$store.state.currency === "BTC" ? "/btc/t_data" : "/omni/t_data",
				daynumber:0
			}
		},
		beforeCreate() {
			this.$store.dispatch('setcurrency', this.$route.meta.currency)
		},
		async mounted() {
			
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
			
			var myChart = echarts.init(document.getElementById('echart-box'))
			// 近两周数据aip请求
			// 判断币种发送不同的请求
			let resp = await getCipherInfo()
			this.info = resp.data

			let res = await maps()
			// console.log(res.data);
			
			// 图表左边的数据
			let leftinfo  = res.data
			
			this.leftinfo = leftinfo
			
			const Axis = res.data
			console.log(Axis);
			this.daynumber = Axis.length
			// x轴数据
			const xAxis = Axis.map(item=>{
				return item.day
			})
			// y轴数据
			const yAxis = Axis.map(item=>{
				return item.tran_count.toString()
			})
			
			this.option.xAxis.data = xAxis
			this.option.series[0].data = yAxis

			// 将数据交给echart
			myChart.setOption(this.option)
			// 执行渲染
			myChart.resize();
			this.mywindow.addEventListener("resize", () => {
				if(this.mywindow.document.body.clientWidth<600){
					this.option.grid.left="8.5%"
					this.option.grid.right="8%"
					this.option.xAxis.axisLabel.interval=6
					myChart.setOption(this.option);
					myChart.resize();
				}else{
					this.option.xAxis.axisLabel.interval=1
					this.option.grid.left="3%"
					this.option.grid.right="0%"
					
					
					myChart.setOption(this.option);
					myChart.resize();
				}
				myChart.resize();
			});
		},
		methods: {
			datahandle(res) {

			},
			gohome() {
				location.reload()
			}
		}
	}
</script>

<style lang="less" scoped>
	.nav_title {
	  font-size: 0.16rem;
	  color: #ffffff;
	  position: absolute;
	  top: -0.4rem;
	
	  // top: 1rem;
	  display: flex;
	  justify-content: space-between;
	  @media screen and (max-width: 1000px) {
	    font-size: 14.192px;
	  }
	  @media screen and (max-width: 555px) {
	    top: -0.3rem;
	  }
	  left: 0;
	  right: 0;
	  width: 100%;
	  margin: 0 auto;
	  display: flex;
	  align-items: center;
	  @media screen and (max-width: 1240px) {
	    width: 100%;
	  }
	  .lefttitle {
	    flex-shrink: 0;
	  }
	  .rightselect {
	    width: 70%;
	
	    display: none;
	    @media screen and (min-width: 535px) {
	      display: flex;
	    }
	    justify-content: flex-end;
	  }
	}
	
	.info {
	  width: 13.79rem;
	  // border: 1px solid red;
	  margin: 0 auto;
	  padding: 30px 0;
	  position: relative;
	  border-radius: 9px;
	  // top: 0;
	  top: -120px;
	  background-color: #ffffff;
	  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	  @media screen and (max-width: 1240px) {
	    width: 92%;
	  }
	  @media screen and (max-width: 800px) {
	    padding: 10px 0;
	  }
	
	  .info_title {
	    line-height: 28px;
	    color: #ffffff;
	    font-size: 20px;
	    font-weight: bold;
	    width: 100%;
	    box-sizing: border-box;
	    padding: 15px 32px;
	    background-color: #5e42be;
	  }
	  .info_con {
	    width: 100%;
	    display: flex;
	    padding: 0 32px;
	    box-sizing: border-box;
	    align-items: center;
	    justify-content: space-between;
	    // flex-wrap: wrap;
	    // border: 1px solid black;
	    @media screen and (max-width: 800px) {
	      flex-wrap: wrap;
	      padding: 0 5px;
	    }
	    // margin-top: 20px;
	    .con_left {
	      width: 208px;
	      flex-shrink: 0;
	
	      // padding-right: 10px;
	      @media screen and (max-width: 800px) {
	        width: 100%;
	      }
	      // border: 1px solid red;
	      .left_con {
	        font-size: 16px;
	        display: flex;
	        flex-direction: column;
	        box-sizing: border-box;
	        @media screen and (max-width: 800px) {
	          flex-direction: row;
	          flex-wrap: wrap;
	          justify-content: space-around;
	          padding-top: 0px;
	        }
	        // 左部分分割线
	        .left_fenge {
	          width: 1px;
	          flex-shrink: 0;
	          height: 80%;
	          background-color: red;
	        }
	        li {
	          display: flex;
	          align-items: center;
	          border-bottom: 1px solid #e8e8e9;
	          height: 77px;
	          @media screen and (max-width: 800px) {
	            width: 38%;
	            height: 50px;
	            padding: 5px 0;
	          }
	        }
	        li:nth-child(3) {
	          @media screen and (max-width: 800px) {
	            border-bottom: 0 !important;
	          }
	        }
	        li:nth-child(4) {
	          @media screen and (max-width: 800px) {
	            border-bottom: 0 !important;
	          }
	        }
	      }
	    }
	
	    // 分割线
	    .fenge {
	      height: 250px;
	      width: 1px;
	      background-color: #e8e8e9;
	      margin: 0 10px;
	      flex-shrink: 0;
	      @media screen and (max-width: 800px) {
	        height: 1px;
	        width: 100%;
	        background-color: #e8e8e9;
	        margin: 10px auto;
	      }
	    }
	    .con_right {
	      width: 1021px;
	      height: 323px;
	    }
	  }
	}
	.triangle {
	  width: 0;
	  height: 0;
	  border-left: 6px solid #ffffff;
	  border-right: 0rem solid #ffffff;
	  border-top: 7px solid transparent;
	  border-bottom: 7px solid transparent;
	  margin: 3px 6px;
	}
	.leftfenge {
	  position: absolute;
	  width: 1px;
	  height: 120px;
	  background-color: #e8e8e9;
	  top: 0.2rem;
	  left: 49.8%;
	  display: none;
	  @media screen and (max-width: 800px) {
	    display: block;
	  }
	}
	
	.hideandshow {
	  @media screen and (max-width: 800px) {
	    display: none;
	  }
	}
	
	.selectnav {
	  padding: 0 5px;
	  position: relative;
	  margin-left: 14px;
	  font-size: 14px;
	  cursor: pointer;
	  .kuang {
	    width: 200px;
	    font-size: 14px;
	    color: #8392a5;
	    background-color: #ffffff;
	    border-radius: 7px;
	    padding: 12px 9px;
	    box-sizing: border-box;
	    position: absolute;
	    z-index: 2;
	    top: 22px;
	    right: 0;
	    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	    .kuangitem {
	      padding: 14px 6px;
	      border-radius: 4px;
	      color: #8392a5;
	    }
	    .kuangitem:hover {
	      background-color: #ebebeb;
	      color: #242424;
	    }
	  }
	}
</style>
