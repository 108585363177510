<template>
		<div>
		<div class="blocks hides" style="border-radius: 9px;">
				<div>
					<div class="nav_title">
						<div class="hands" @click="goback">{{xiaobiaoti}}</div>
						<div class="triangle" ></div>
						<div >
							Transaction
						</div>
					</div>
					<div class="block_info" style="box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16);">
						<ul>
							<li>
								<div class="block_name">
									<el-tooltip class="item" effect="dark" content="Unique identifier used to identify a particular transaction" placement="right">
										 <span>TXID:</span>
									</el-tooltip>
								</div>
								<div class="block_value" style="word-wrap: break-word ;padding-right: 10px; box-sizing: border-box;">
									{{hashinfo.txid}}
									<img id="copybtn" @click="copytext" :data-clipboard-text="hashinfo.txid" src="../../assets/imgs/ctrlc.png" style="height: 17px;width: 17px; cursor: pointer;">
								</div>
							</li>
							<li>
								<div class="block_name">Status：</div>
								<div class="block_value">
									<p v-text="hashinfo.confirmations===null?'0 Confirmations':`${hashinfo.confirmations} Confirmations`"></p>
								</div>
							</li>
							<li>
								<div class="block_name">
									<el-tooltip class="item" effect="dark" content="Time this transaction was broadcast to the network, YYYY-MM-DD" placement="right">
										<span>Time:</span>
									</el-tooltip>
								</div>
								<div class="block_value">{{hashinfo.blocktime}}</div>
							</li>
							<li>
								<div class="block_name">
									<el-tooltip class="item" effect="dark" content="Total size of this transaction" placement="right">
										<span>Size:</span>
									</el-tooltip>
								</div>
								<div class="block_value">{{hashinfo.size}}</div>
							</li>
							<li>
								<div class="block_name">Vsize：</div>
								<div class="block_value">{{hashinfo.vsize}}</div>
							</li>
							<li>
								<div class="block_name">
									<el-tooltip class="item" effect="dark" content="Block this transaction was mined and confirmed in" placement="right">
										<span >Block：</span>
									</el-tooltip>
								</div>
								<div class="block_value">
									<router-link :to="`/block/${hashinfo.block_number}`">
										<button class="links" style="background-color: #FFFFFF;" v-text="hashinfo.block_number===null?' ':hashinfo.block_number">
											
										</button>
									</router-link>
								</div>
							</li>
							<li>
								<div class="block_name">Total Input：</div>
								<div class="block_value">{{hashinfo.total_vin/100000000}} BTC</div>
							</li>
							<li>
								<div class="block_name">Total Output：</div>
								<div class="block_value">{{hashinfo.total_vout/100000000}} BTC</div>
							</li>
							<li>
								<div class="block_name">
									<el-tooltip class="item" effect="dark" content="Total fees paid to process this transaction" placement="right">
										<span>Fee:</span>
									</el-tooltip>
								</div>
								<div class="block_value">{{hashinfo.fee/100000000}} BTC</div>
							</li>
							<li>
								<div class="block_name">
									Other Info:
								</div>
								<div class="block_value links"><a :href='`${lainjie}/hash/${hashinfo.txid}`' target="_blank">Raw Data</a></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- 手机端 -->
			<div class="shows shouji">
				<div class="nav_title" style="width: 92%; margin: 14px auto;">
					<div class="hands" @click="goback">Bitcoin Explorer</div>
					<div class="triangle" ></div>
					<div >
						Transaction
					</div>
				</div>
				<div class="s_info" style="border-radius: 9px; padding-left: 10px; padding-top: 15px;font-size: 16px; box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16);">
					<div class="info_item" style="height: 60px; padding-bottom: 10px; display: flex; flex-direction: column; justify-content: center;">
						<div class="item_name" style="margin-bottom: 4px;">
							<el-tooltip class="item" effect="dark" content="Unique identifier used to identify a particular transaction" placement="right">
								 <span>TXID:</span>
							</el-tooltip>
						</div>
						<div style="word-wrap: break-word;">
							{{hashinfo.txid}}
						</div>
					</div>
					<div style="height: 60px;display: flex; flex-direction: column; justify-content: center; border-bottom: 1px solid #EFEFEF;">
						<div style="margin-bottom: 4px;">Status：</div>
						<div>
							<p v-text="hashinfo.confirmations===null?'0 Confirmations':`${hashinfo.confirmations} Confirmations`"></p>
						</div>
					</div>
					<div class="info_item">
						<div>
							<el-tooltip class="item" effect="dark" content="Time this transaction was broadcast to the network, YYYY-MM-DD" placement="right">
								<span>Time:</span>
							</el-tooltip>
						</div>
						<div class="item_name">{{hashinfo.blocktime}}</div>
					</div>
					<div class="info_item">
						<div>Size：</div>
						<div class="item_name">{{hashinfo.size}}</div>
					</div>
					<div class="info_item">
						<div>Vsize：</div>
						<div class="item_name">{{hashinfo.vsize}}</div>
					</div>
					<div class="info_item">
						<div>
							<el-tooltip class="item" effect="dark" content="Block this transaction was mined and confirmed in" placement="right">
								<span>Block：</span>
							</el-tooltip>
						</div>
						<div class="item_name">
							<router-link :to="`/block/${hashinfo.block_number}`">
								<button class="links" style="background-color: #FFFFFF;" v-text="hashinfo.block_number===null?' ':hashinfo.block_number">
									
								</button>
							</router-link>
						</div>
					</div>
					<div class="info_item">
						<div>Total Input：</div>
						<div class="item_name">
							{{hashinfo.total_vin/100000000}} BTC
						</div>
					</div>
					<div class="info_item">
						<div>Total Output:</div>
						<div class="item_name">{{hashinfo.total_vout/100000000}} BTC</div>
					</div>
					<div class="info_item">
						<div>
							<el-tooltip class="item" effect="dark" content="Total fees paid to process this transaction" placement="right">
								<span>Fee:</span>
							</el-tooltip>
						</div>
						<div class="item_name">{{hashinfo.fee/100000000}} BTC</div>
					</div>
					<div class="info_item">
						<div>
							Other Info:
						</div>
						<div class="item_name links">
							<a :href='`${lainjie}/hash/${hashinfo.txid}`' target="_blank">Raw Data</a>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		
</template>

<script>
	import 'element-ui/lib/theme-chalk/display.css';
	import Clipboard from 'clipboard'
	import {Message} from 'element-ui'
	export default {
		data(){
			return {
				list:[],
				btncodes:true,
				lainjie:window.location.origin,
				xiaobiaoti:""
			}
		},
		methods:{
			copytext(){
				var clipboard = new Clipboard('#copybtn');
				clipboard.on('success', e => {
					console.log(e)
					Message({
						message: `Copied:${e.text}`,
						type: 'success'
					});
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					// 不支持复制
					Message({
						message: '该浏览器不支持自动复制',
						type: 'warning'
					});
				// 释放内存
				clipboard.destroy()
				})
			},
			chablock(block){
				this.$router.push(`/block/${block}`)
			},
			goback(){
				this.$router.push('/')
			}
		},
		props:['hashinfo'],
		created() {
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
		}
	}
</script>

<style lang="less" scoped>
	.blocks{
		width: 13.79rem;
		margin: 0px auto 60px;
		@media screen and (max-width:1240px){
			width: 92%;
		}
	}
	// 小导航
	.nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 1379px;
		margin: 14px auto;
		display: flex;
		align-items: center;
		@media screen and (max-width:1400px) {
			width: 100%;
		}
	}
	.triangle{
		width:0;
		height: 0;
		border-left: 6px solid #242424;
		border-right: 0rem solid #242424;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		margin:3px 6px;
	}
	
	.block_info{
		width: 13.79rem;
		margin: 0 auto;
		border-radius: 9px;
		background-color: #FFFFFF;
		display: flex;
		color: #242424;
		// 临时
		@media screen and (max-width:1240px){
			width: 100%;
		}
		ul{
			width: 100%;
			li{
				display: flex;
				justify-content: space-between;
				font-size: 15px;
				line-height: 20px;
				padding: 19px 0 ;
				box-sizing: border-box;
				border-bottom: 1px solid #DFDFDF ;
				.block_name{
					width: 24%;
					padding-left: 33px;
					box-sizing: border-box;
					flex-shrink: 0;
					
				}
				.block_value{
					width: 75%;
				}
			}
		}
		
	}
	// 手机端
	.s_info{
		width: 92%;
		margin: 0 auto;
		padding-right: 10px;
		box-sizing: border-box;
		background-color: #FFFFFF;
		
	}
	.info_item{
		height: 60px;
		display: flex; 
		border-bottom: 1px solid #EFEFEF;
		flex-direction: column; 
		justify-content: center;
			.item_name{
				margin-top: 6px;
			}
	}
	
	.hides{
		@media screen and (max-width:700px){
			display: none;
		}
	}
	.shows{
		@media screen and (max-width:700px){
			display: block !important;
		}
	}
	.shouji{
		display: none;
	}
	
	
	
	
	
	
	
	.links{
		color: #0082FF;
	}
	.links:hover{
		text-decoration: underline;
		cursor: pointer;
	}
	
</style>

