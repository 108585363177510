<template>
	<div style="margin-bottom: 20px;">
		
		<div class="hide">
			<div class="titletext">
				<div>Latest Blocks</div>
			</div>
			<div>
				<div class="list_center">
					<!-- 表头 -->
					<ul class="table_header">
						<li class="font-x">Blocks</li>
						<li class="tow">Timestamp</li>
						<li class="three">Miner</li>
						<li class="frie">Transactions</li>
						<li>Size(KB)</li>
					</ul>
				</div>
			</div>
			
			<div v-for="(item,index) in blocklist" :key="index" class="lists">
				<div class="list_center">
					<ul class="table_con" >
						<li style="color: #0082FF;">
							<router-link :to="`/block/${item.number}`">
								<span style="color: #0082FF;" class="text_underline">
									{{item.number}}
								</span>
							</router-link>
						</li>
						<li class="tow">{{item.timestamp}}</li>
						
						<li class="three">
							<router-link :to="`/address/${item.miner}`">
								<span style="color: #0082FF; cursor:pointer;" class="text_underline">
									{{item.miner}}
								</span>
							</router-link>
						</li>
						<li class="frie">{{item.transaction_count}}</li>
						<li>{{item.size}}</li>
					</ul>
				</div>
			</div>
			<!-- 查看全部区块按钮 -->
		</div>
		
		<!-- 移动端 -->
		<div>
			<div class="show yidong">
				<div class="font-x" style="font-weight: bold; background-color: #EFEFEF; height: 40px; line-height: 40px;">
					<div class="s_title">
						Latest Blocks
					</div>
				</div>
				<div  v-for="(item,index) in blocklist" :key="index" :class="{s_list:index%2==0}" style="border-bottom: 1px solid #EAEAEA;">
					<div style="width: 96%; margin: 0 auto; display: flex; ">
						<ul style="font-size: 14px; width: 30%; line-height: 40px;">
							<li><div class="list_name">Height</div></li>
							<li><div class="list_name">Time</div></li>
							<li><div class="list_name">Miner</div></li>
							<li><div class="list_name">Transactions</div></li>
							<li><div class="list_name">Size(KB)</div></li>
						</ul>
						<ul style="flex-shrink: 0; line-height: 40px; width: 70%; font-size: 14px;">
							<li><div class="list_value">
								<router-link :to="`/block/${item.number}`">
									<span style="color: #0082FF;" class="text_underline">
										{{item.number}}
									</span>
								</router-link>
							</div></li>

							<li><div class="list_value">{{item.timestamp}}</div></li>
							<li><div class="list_value" style="overflow: hidden;text-overflow: ellipsis;">
								<router-link :to="`/address/${item.miner}`">
									<span style="color: #0082FF;" class="text_underline">
										{{item.miner}}
									</span>
								</router-link>
							</div></li>
							<li><div class="list_value">{{item.transaction_count}}</div></li>
							<li><div class="list_value">{{item.size}}</div></li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>

		<!-- 共用的按钮 -->
		<div class="more" v-if="!$store.state.viewbutton">
			<button class="more_btn" @click="getblackList(numbers)"  >
				Load more <img src="../../assets/imgs/more.png" style="width:22px;height: 22px;">
			</button>
		</div>
		
	</div>
</template>

<script>
	import {blackList,transactionlist} from "../../axios/index.js"
	import {omniblockList} from "../../axios/omniaxios/index.js"
	import transaction from "../transaction/transaction.vue"
	import 'element-ui/lib/theme-chalk/display.css';
	export default {
		data(){
			return {
				list:[],
				blocklist:[],
				transactionlists:[],
				hoverindex:-1,
				viewbutton:true,
				pages:[1,2,3,4,'...',10],
				// 总页数
				transaction_count:0,
				// 当前页数
				currentpage:1,
				// 每页条数
				meiyeyeshu:10,
				allview:false,
				num:20,
				lianjie:-1,
				numbers:0
			}
		},
		components:{
			transaction
		},
		methods:{
			async getblackList(pages){
				if(this.$store.state.currency==="BTC"){
					
					let res = await blackList(this.meiyeyeshu,pages);
					this.btchandldata(res)
				}else if(this.$store.state.currency==="OMNI"){
					let res = await omniblockList(this.meiyeyeshu)
					this.omnihandldata(res.data)
				}
			},
			btchandldata(res){
				this.transaction_count=Math.ceil(res.count/this.meiyeyeshu)
				let result = res.data.map((item)=>{
								 return {
									 miner:item.miner,
									 number:item.number,
									 size:((item.size/1024).toFixed(2)).toString(),
									 hash:item.hash,
									 timestamp:item.timestamp,
									 transaction_count:(item.transaction_count).toString()
								 }
				})
				
				// 这里时拼接 手机端下拉加载用的就是这个
				// this.blocklist=this.blocklist.concat(result);
				this.numbers = result.slice(-1)[0].number
				console.log(result)
				this.handtime(result,'timestamp')
				//这里是替换 
				this.blocklist=this.blocklist.concat(result) 
				// 格式化时间
				
			},
			omnihandldata(res){
				console.log(res)
				this.blocklist = res.map((item)=>{
					return {
						block:item.block,
						block_hash:item.block_hash,
						block_timestamp:item.block_timestamp,
						total_usd:item.total_usd
					}
				})
				this.handtime(this.blocklist,'block_timestamp')
			},
			handtime(times,ziduan){
				console.log(times)
				for(let i = 0;i<times.length;i++){
								 var dates= new Date(times[i][ziduan]*1000)
								 var y = dates.getFullYear();    
								 var m = dates.getMonth() + 1;    
								 m = m < 10 ? ('0' + m) : m;    
								 var d = dates.getDate();    
								 d = d < 10 ? ('0' + d) : d;
								 var h = dates.getHours();  
								 h = h < 10 ? ('0' + h) : h;  
								 var minute = dates.getMinutes();  
								 var second = dates.getSeconds();  
								 minute = minute < 10 ? ('0' + minute) : minute;    
								 second = second < 10 ? ('0' + second) : second; 
								 times[i][ziduan]=y + '-' + m + '-' + d+' '+h+':'+minute+':'+second
				}
			},
			viewall(){
				if(this.$store.state.viewbutton){
					// this.$router.push({path:'/'})
					if(this.$route.meta.currency=="BTC"){
						this.meiyeyeshu=20
					}else if(this.$route.meta.currency=="BTC"){
						this.meiyeyeshu=10
					}
					
					this.getblackList(this.numbers)
					this.$store.commit('setviewbutton')
					
				}else{
					if(this.$route.meta.currency==="BTC"){
						this.meiyeyeshu=10
						this.getblackList(this.meiyeyeshu)
						this.$store.commit('setviewbutton')
						
					}else if(this.$route.meta.currency==="BTC"){
						
					}
					
				}
			},
			clicks(index){
				if(index==="...") return
				this.currentpage=index
				console.log(this.currentpage)
				this.getblackList(this.currentpage)
			},
			pros(){
				if(this.currentpage!=1){
					this.currentpage=this.currentpage-1
					this.getblackList(this.currentpage)
				}
			},
			nexts(){
				if(this.currentpage!=this.transaction_count){
					this.currentpage=this.currentpage+1
					this.getblackList(this.currentpage)
				}
			},
			handleChange(value) {
			    this.meiyeyeshu=value;
				this.getblackList();
			},
			goaddress(address){
				this.$router.push(`/address/${address}`)
			},
			gotranscations(){
				this.$router.push('/untransactions')
			}
		},
		async created() {
			this.getblackList()
		},
		computed:{
			sumpage(){
				
				if(this.currentpage==="...") return
				if(this.currentpage<4){
					return [1,2,3,4,'...',this.transaction_count]
				}else if(this.transaction_count-3>=this.currentpage&&this.currentpage>=4){
					 return [1,'...',this.currentpage-1,this.currentpage,this.currentpage+1,'...',this.transaction_count]
				}else if(this.currentpage>this.transaction_count-3){
					return [1,'...',this.transaction_count-3,this.transaction_count-2,this.transaction_count-1,this.transaction_count]
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	
	.titletext{
		font-size:18px ;
		width: 100%;
		background-color: #f9fafb;
		margin: 0 auto;
		height: 30px;
		padding-left:0px ;
	}
	
	.list_center{
		width: 98%;
		margin: 0 auto;
		@media screen and (max-width:1240px){
			width: 98%;
		}
	}
	.table_header{
		background-color: #F8FAFD;
		border-radius: 4px;
		margin: 10px 0;
		width: 100%;
		height: 40px;
		padding: 0 15px;
		box-sizing: border-box;
		// background-color: #EFEFEF;
		display: flex;
		color: #707070;
		font-size: 16px;
		line-height: 40px;
		justify-content: space-between;
	}
	.table_con{
		width: 100%;
		height: 48px;
		display: flex;
		color: #707070;
		font-size: 15px;
		line-height: 48px;
		justify-content: space-between;
	}
	
	.table_header li:first-child,.table_con li:first-child{
		width: 130px;
		flex-shrink: 0;
	}
	.table_header .tow,.table_con .tow{
		width: 152px;
		font-size: 15px;
		color: #8392A5;
		flex-shrink: 0;
	}
	.table_header .three,.table_con .three{
		width: 26%;
		font-size: 15px;
		color: #8392A5;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.table_header .frie,.table_con .frie{
		font-size: 15px;
		color: #8392A5;
		width: 100px;
	}
	.table_header li:last-child,.table_con li:last-child{
		width: 120px;
		font-size: 15px;
		color: #8392A5;
		padding-left: 30px;
		box-sizing: border-box;
	}
	
	.font-x{
		font-size: 15px;
		color: #8392A5;
	}
	
	
	.lists{
		border-bottom: 1px solid #EAEAEA;
		width: 98%;
		margin: 0 auto;
		@media screen and (max-width:1240px){
			width: 98%;
		}
	}
	.lists:last-child{
		border-bottom:none
	}
	
	.lists:hover{
		background: #FDFDFD
	}
	
	// 移动端
	.yidong{
		display: none;
	}
	.s_title{
		width: 96%;
		margin: 0 auto;
	}
	.s_list{
		width: 100%;
		background: #FDFDFD;
		border-bottom: 1px solid #EAEAEA;
		
	}
	.list_name{
		width: 150px;
	}
	
	.showall{
		width: 13.79rem;
		margin: 12px 0;
		@media screen and (max-width:1240px){
			width: 92%;
		}
	}
	.hide{
		width:13.79rem;
		margin: -80px auto 0px;
		background-color: #FFFFFF;
		overflow: hidden;
		border-radius: 2px;
		padding-bottom: 10px;
		@media screen and (max-width:1240px){
			width: 92%;
		}
		@media screen and (max-width:800px){
			display: none;
		}
	}
	.show{
		@media screen and (max-width:800px){
			display: block !important;
		}
	}
	
	.text_underline:hover{
		text-decoration: underline;
	}
	
	.pagenav{
		overflow: hidden;
		width: -webkit-fit-content;
		margin: 10px auto;
		@media screen and (max-width:766px){
			margin: 5px auto;
		}
		li{
			float: left;
			
			font-size: 16px;
			font-weight: bold;
			padding: 14px 20px ;
			text-align: center; 
			border: 1px solid #EAEAEA;
			@media screen and (max-width:766px){
				padding: 14px 14px;
			}
		}
	}
	.gaoliang{
		background-color: #0082FF !important;
		color: white !important;
		border: #0082FF 1px solid !important;
	}
	
	
	.more_btn{
		width: 1.70rem;
		height: 0.4rem;
		background-color: #FFFFFF;
		border: 1px solid #3498E1;
		color: #3498E1;
		border-radius: 4px;
		display: block;
		font-size: 0.20rem;
		margin: 30px auto;
		cursor: pointer;
	}
	.articles-font{
		width: 1379px;
		margin: 0 auto;
		@media screen and (max-width:1400px){
			width: 96%;
		}
		font-size: 16px;
		@media screen and (max-width:766px){
			font-size: 8px;
		}
		display: flex;
		align-items: center;
	}
</style>
