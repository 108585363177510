<template lang="">
    <div>
    
  <el-select  class="dropdown" v-model="value" >
    <el-option
      v-for="item in options"
      :key="item.value"
      :value="item.value" 
      class="dropdown_bar"
      >
      <img src="../../assets/imgs/BTC.png" alt="" style="height:30px;margin-right:10px">
      {{item.label}}
    </el-option>
  </el-select>
    </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: "Bitcoin Mainnet",
          label: "Bitcoin Mainnet",
        },
        {
          value: "Bitcoin Testnet",
          label: "Bitcoin Testnet",
        },
      ],
      value: "Bitcoin Mainnet",
    };
  },
};
</script>
<style lang="less" >
.popper__arrow {
  top: 0px !important;
}
.dropdown {
  .el-input__inner {
    border-radius: 14px !important;
    background: url(../../assets/imgs/BTC.png);
    background-repeat: no-repeat;
    background-size: 28px; //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
    background-position: 0.1rem 5px; //在input中定位置  这两个参数是x  y坐标
    padding: 0 0 0 0.2rem; //需要设置padding 把placeholder向右移
    box-sizing: border-box;
    font-size: 0.16rem;
    text-align: center;
	@media screen and (max-width:340px){
		text-align: right;
	}
  }
}
.el-select-dropdown__item {
  padding: 0 10px;
}
</style>