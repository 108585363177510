import axios from "axios"
import { Message } from "element-ui"

// 开发环境
// var baseURL = "http://dbexplorer.sectoken.io/api/btc"
// 生产环境
// var baseURL = location.href+"api/btc"
var baseURL = location.protocol+"//"+location.host+"/api/btc"

axios.defaults.timeout = 200000;


axios.interceptors.request.use(
	config =>{
		config.baseURL = baseURL
		return config;
	},
	error=>{
		return Promise.error(error);
	}
)


axios.interceptors.response.use(
	response=>{
		if (response.status === 200) {
		    return Promise.resolve(response);
		} else {
		    return Promise.reject(response);
		}
	},error=>{
		Message({
		    message: error,
		    type: "error"
		})
		return Promise.reject(error)
	}
)

// 请求取消
let CancelToken = axios.CancelToken;
window.cancle = null;
// 封装get方法
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                window.cancle = c;
            })
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
	
}
