<template>
	<div>
		<div class="home_banner">
			<div class="banner_div">
				<headers></headers>
			</div>
		</div>
		<ominhomestand></ominhomestand>
		
		<newestblock></newestblock>
	</div>
</template>

<script>
	import headers from "../../components/header/header.vue"
	import ominhomestand from "../../components/home_stand/home_stand.vue"
	import newestblock from "../../components/home_cneter/newest_block.vue"
	export default {
		components:{
			headers,
			ominhomestand,
			newestblock
		},
		mounted() {
			this.$store.dispatch('setcurrency',this.$route.meta.currency)
		}
	}
</script>

<style lang="less" scoped>
	.home_banner{
		height: 373rem;
		width: 100%;
		background: url(../../assets/imgs/banner.png) no-repeat,linear-gradient(to bottom,#0D0041,#0A1576);
		background-size: 100% 100%;
		position: relative;
		@media screen and (max-width:766px){
			height: 340rem;
		}
		.banner_div{
			position: absolute; 
			height: 200rem; 
			top: 0 ; 
			bottom: 0;
			left: 0; 
			right: 0; 
			margin: auto auto;
			@media screen and (max-width:766px){
				height: 180rem;
			}
		}
	}
</style>
