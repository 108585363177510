<template>
	<div>
		<el-collapse-transition>
			<transition name="el-zoom-in-top">
				<div v-show="shows" class="kuang">
					<div class="items">
						<router-link :to="`/blocks`">
							<span style="cursor: pointer; color: #242424; display: block;">
								Blocks
							</span>
						</router-link>
						
					</div>
					<div class="items">
						<router-link :to="`/pending`">
							<span style="cursor: pointer; color: #242424;display: block;">
								Pending
							</span>
						</router-link>
					</div>
					
					
					<div class="navboxitem">
					  <div
					  @click.stop="ifnetlist=!ifnetlist"
					    style="
					      height: 100%;
					      width: 100%;
					      display: flex;
					      justify-content: space-between;
					      align-items: center;
					    "
					  >
					    <div>Network</div>
					    <div>
					      <img
					        src="../assets/imgs/xia.png"
					        style="height: 16px; width: 16px"
					      />
					    </div>
					  </div>
					  <el-collapse-transition>
					    <div
					      v-show="ifnetlist"
					      style="
					        background-color: #ffffff;
					        display: flex;
					        justify-content: flex-end;
					      "
					    >
					      <div
					        style="
					          width: 97%;
					          border-left: 2px solid #3498e1;
					          margin-left: 19px;
					          padding-left: 10px;
					          box-sizing: border-box;
					        "
					      >
					        <div
					          class="childerbox"
					          v-for="(items, index) in netlist"
					          :key="index"
					          @click="gotoapi(items.api)"
					        >
					          {{ items.value }}
					        </div>
					      </div>
					    </div>
					  </el-collapse-transition>
					</div>
				</div>
			</transition>
		</el-collapse-transition>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				toggles:true,
				netlist:"",
				ifnetlist: false
			}
		},
		props:['shows'],
		methods:{
			gopush(val){
				// thiss.$router.push(`/${val}`)
			},
			gotoapi(api) {
			  if (api == window.location.hostname) {
			    return;
			  } else {
			    const urld = window.location.protocol + "//" + api;
			    window.open(urld);
			  }
			}
		},
		mounted() {
			this.netlist = this.GLOBAL.option;
		}
	}
</script>

<style scoped="scoped" lang="less">
	
	.kuang{
		
		background-color: #ffffff;
		position: absolute;
		top: 0.65rem;
		z-index: 5;
		width: 100%;
		left: 0;
		@media screen and (min-width: 535px) {
		  padding: 0 !important;
		}
		.items{
			width: 92%;
			margin: 0 auto;
			height: 50px;
			line-height: 50px;
			font-size: 15px;
			font-weight: bold;
			border-bottom: 1px solid #e8e8e8;
		}
	}
	.navboxitem {
	  width: 92%;
	  line-height: 50px;
	  border-bottom: 1px solid #e8e8e8;
	  font-size: 15px;
	  font-weight: bold;
	  margin: 0 auto;
	}
	.childerbox {
	  padding: 5px;
	  line-height: 27px;
	  border-radius: 4px;
	  cursor: pointer;
	}
	.childerbox:hover {
	  background-color: #ebebeb;
	}
</style>
