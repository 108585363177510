<template>
	<div>
		<indextop></indextop>
		
		<div style="margin: 28px 0;">
			<headers></headers>
		</div>
		<!-- 小标题 -->
		<div class="minnav">
		  <div class="nav_title">
			  <div class="xiao">
				  <div class="hands" @click="$router.push('/')">{{xiaobiaoti}}</div>
				  <div class="triangle"></div>
				  <div>Pending Transactions</div>
			  </div>
		  </div>
		  <div class="nav_right">
		    Show &nbsp;
		    <div class="nav_btn" id="chooselist">
		      <input
		        type="button"
		        v-model="pagesize"
		        class="btn_input"
				@click="ifshow=!ifshow"
		      />
		      <div class="btn_left" @click="ifshow=!ifshow">
		        <div class="el-icon-caret-top icon_btn"></div>
		        <div class="el-icon-caret-bottom icon_btn"></div>
		      </div>
		      <div class="nav_list" v-show="ifshow" >
		        <div class="nav_list_one" @click="choosenum(20)">20</div>
		        <div class="nav_list_one" @click="choosenum(50)">50</div>
		        <div class="nav_list_one" @click="choosenum(100)">100</div>
		      </div>
		    </div>
		    &nbsp; Records
		  </div>
		</div>
		
		<div class="con">
			<!-- 便利 -->
			<div class="list" v-for="(value,index) in list " :key="index">
				<div class="one">
					<div class="ziduan">
						<div class="name">TXID：</div>
						<div class="txidvalue">
							<router-link :to="`/hash/${value.hash}`">
								<span class="links">{{value.hash}}</span>
							</router-link>
						</div>
					</div>
					<div class="ziduan">
						<div class="name">Time：</div>
						<div class="timevalue">{{value.timestamp | formData}}</div>
					</div>
				</div>
				<div class="two">
					<div class="ziduan">
						<div class="name">Fee：</div>
						<div class="feevalue"><span style="color: #0082FF;">{{value.fee | xiaoshu}}</span></div>
					</div>
					<div class="ziduan">
						<div class="name">Size：</div>
						<div class="sizevalue"><span style="color:#0082FF;">{{value.size}}</span></div>
					</div>
				</div>
				<div class="three">
					<div class="ziduan">
						<div class="name">Value</div>
						<div class="pcyin">{{value.value | xiaoshu}}</div>
					</div>
					<div class="ziduan shouji" >{{value.value | xiaoshu}}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import indextop from "../components/indextop.vue"
	import headers from "../components/header/header.vue"
	export default{
		components:{
			indextop,
			headers
		},
		data(){
			return {
				// 小标题
				xiaobiaoti:"",
				// 每页条数下拉框
				ifshow:false,
				// 每页条数
				pagesize:20,
				// 列表数据
				list:[],
				websock:null
			}
		},
		created() {
			let urlhost = location.host
			this.xiaobiaoti = 'Bitcoin Explorer'
			
			// WebSocket链接
			// const wsuri = "ws://aexplorer.sectoken.io/ws";
			console.log(location.protocol);
			if(location.protocol !== 'https:') {
				var wsuri = "ws://"+location.host+"/ws";
			}else {
				var wsuri = "wss://"+location.host+"/ws";
			}
			this.websock = new WebSocket(wsuri)
			// 建立链接时触发 触发时发送请求
			 this.websock.onopen = (res)=>	{
				 this.websock.send("1");
			 } 
			 // 数据每次相应返回时触发
			 this.websock.onmessage = (e)=>{
				 // 通过e.data获取返回的数据
				 // json转对象json.parse(json数据)
				 const redata = JSON.parse(e.data);
				 if(this.list.length<this.pagesize){
					 this.list.push(redata)
				 }else{
					 if(this.list.length>this.pagesize){
						 if(this.pagesize==20){
							 this.list = this.list.slice(30)
						 }
						 if(this.pagesize==50){
							 this.list = this.list.slice(50)
						 }
					 }
					 this.list.shift()
					 this.list.push(redata)
				 }
			 }
		},
		methods:{
			choosenum(val){
				this.pagesize = val
				this.ifshow=false
			}
		},
		beforeDestroy(){
			this.websock.close()
		},
		filters:{
			formData(value) {
			    let data = new Date((value) * 1000);
			    let y = data.getFullYear();
			    let MM = data.getMonth() + 1;
			    MM = MM < 10 ? "0" + MM : MM;
			    let d = data.getDate();
			    d = d < 10 ? "0" + d : d;
			    let h = data.getHours();
			    h = h < 10 ? "0" + h : h;
			    let m = data.getMinutes();
			    m = m < 10 ? "0" + m : m;
			    let s = data.getSeconds();
			    s = s < 10 ? "0" + s : s;
			    return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
			},
			xiaoshu(val){
				return parseFloat(val/100000000) 
			}
		}
		
		
	}
</script>

<style lang="less" scoped="scoped">
	// 小标题
	.minnav {
	  width: 13.79rem;
	  margin: 0 auto;
	  @media screen and (max-width: 1240px) {
	    width: 92%;
	  }
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  height: 40px;
	  @media screen and(max-width:520px) {
	    flex-direction: column;
	    align-items: flex-start;
	  }
	  @media screen and(max-width:860px) {
	    // height: 60px;
		flex-direction: column;
		align-items: flex-start;
		height: 76px;
		margin-bottom: 15px;
	  }
	}
	.nav_title {
	  font-size: 16px;
	  color: #242424;
	  left: 0;
	  right: 0;
	  display: flex;
	  align-items: center;
	  @media screen and (max-width: 589px) {
	    flex-direction: column;
	    align-items: flex-start;
	  }
	  .xiao {
	    display: flex;
	    @media screen and (max-width: 589px) {
	      width: 100%;
	    }
	  }
	  .tishiyu {
	    margin-left: 16px;
	    color: #8392a5;
	    @media screen and (max-width: 589px) {
	      margin-left: 0px;
	    }
	  }
	}
	.triangle {
	  width: 0;
	  height: 0;
	  border-left: 6px solid #242424;
	  border-right: 0rem solid #242424;
	  border-top: 7px solid transparent;
	  border-bottom: 7px solid transparent;
	  margin: 3px 6px;
	}
	.nav_right {
	  font-size: 16px;
	  font-family: Microsoft YaHei;
	  font-weight: 400;
	  line-height: 21px;
	  color: #707070;
	  opacity: 1;
	  display: flex;
	  align-items: center;
	  @media screen and (max-width: 950px) {
	    margin-left: auto;
	  }
	  .nav_btn {
	    width: 50px;
	    height: 30px;
	    background: rgba(255, 255, 255, 0.39);
	    border: 1px solid #d2d2d2;
	    opacity: 1;
	    border-radius: 3px;
	    cursor: pointer;
	    display: flex;
	    justify-content: flex-end;
	    position: relative;
	  }
	  .nav_btn:active {
	    border: 1px solid #0082ff;
	  }
	
	  .btn_input {
	    width: 30px;
	    font-size: 14px;
	    font-family: Microsoft YaHei;
	    font-weight: 400;
	    line-height: 19px;
	    color: #707070;
	    opacity: 1;
	    background: #fff;
	    border-radius: 3px;
	  }
	  .btn_left {
	    height: 100%;
	    width: 20px;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	  }
	  .icon_btn {
	    height: 12px;
	    width: 12px;
	    text-align: center;
	    font-size: 12px;
	  }
	
	  .nav_list {
	    width: 48px;
	    background: #fff;
	    position: absolute;
	    top: 31px;
	    border: 1px solid rgba(0, 0, 0, 0.541);
	  }
	  .nav_list_one {
	    height: 20px;
	    width: 100%;
	    font-size: 14px;
	    text-align: center;
	    line-height: 20px;
	    color: #000;
	  }
	  .nav_list_one:hover {
	    background: #0082ff;
	  }
	}
	
	// 列表内容
	.con{
		width: 13.79rem;
		margin: 0 auto;
		min-height: 700px;
		padding-bottom: 20px;
		box-sizing: border-box;
		@media screen and (max-width:1240px){
			width: 92%;
		}
		.list{
			background-color: #FFFFFF;
			border-radius: 2px;
			padding-left: 18px;
			padding-right: 18px;
			box-sizing: border-box;
			height: 100px;
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid #EBEBEB;
			transition:box-shadow 0.3s;
			@media screen and (max-width:678px){
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				height: 140px;
				padding-left: 10;
			}
			.one{
				width: 610px;
				// border: 1px solid red;
				overflow: hidden;
				@media screen and (max-width:678px){
					width: 100%;
					
					flex-shrink: 0;
				}
				.txidvalue{
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.two{
				flex-shrink: 0;
				margin-left: 10px;
				width: 150px;
				// border: 1px solid red;
				@media screen and (max-width:678px){
					margin-left: 0px;
					
				}
				.name{
					width: 0.70rem;
				}
			}
			.three{
				flex-shrink: 0;
				width: 140px;
				margin-left: 10px;
				// border: 1px solid red;
				@media screen and (max-width:678px){
					margin-left: 0px;
				}
			}
		}
		.list:hover{
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		}
	}
	
	.ziduan{
		font-size: 14px;
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		@media screen and (max-width:678px){
			margin-top: 11.5px;
			margin-bottom: 0px;
		}
	}
	
	.name{
		flex-shrink: 0;
		@media screen and (max-width:678px){
			width: 0.7rem;
		}
		
	}
	.shouji{
		@media screen and (max-width:678px){
			display: none;
		}
	}
	.shoujishow{
		display: none;
		@media screen and (max-width:678px){
			display: inline;
		}
		
	}
	.pcyin{
		display: none;
		@media screen and (max-width:678px){
			display: block;
		}
	}
</style>
