<template>
	<div class="home">
		<indextop></indextop>
		
		<div class="home_banner" style="border: 0px solid transparent;">
			
			<div class="banner_div">
				<div
				  style="
				    text-align: center;
				    font-size: 0.25rem;
				    color: #f3f3f3;
				    
				  "
				  class="biaoti"
				>
				  The Bitcoin Blockchain Explorer
				</div>
				<headers :colorss='true'></headers>
			</div>
		</div>
		<homestand></homestand>
		<newestblock></newestblock>
	</div>
</template>

<script>
	import indextop from "../indextop.vue"
	import headers from "../header/header.vue" 
	import home_stand from "../home_stand/home_stand.vue"
	import newest_block from "../home_cneter/newest_block.vue"
	export default {
		components:{
			indextop,
			homestand:home_stand,
			newestblock:newest_block,
			headers
		},
		data(){
			return {
				flag:null
			}
		},
		created() {
			this.flag=localStorage.getItem('isMobile')
		}
		
	}
</script>

<style lang="less" scoped>
	.home{
		
	}
	.biaoti{
		margin: 20px 0 20px;
		@media screen and (max-width:567px){
			margin: 30px 0 10px;
		}
	}
	.home_banner{
		height: 3.73rem;
		@media screen and (max-width:503px){
			height: 3.73rem;
		}
		width: 100%;
		background: url(../../assets/imgs/banner.png),linear-gradient(to bottom,#0D0041,#0A1576);
		position: relative;
		
		.banner_div{
			position: absolute; 
			height: 3.1rem;
			top: 0 ; 
			bottom: 0;
			left: 0; 
			right: 0; 
			margin: auto auto;
			@media screen and (max-width:567px){
				height: 270px;
			}
		}
	}
	
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  .zhong{
		  display: flex; width: 92%; align-items: center;
		  @media screen and (max-width:435px){
			  width: 99%;
		  }
	  }
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 20px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
	
	
</style>
