<template>
	<div class="omniProperty">
		<!-- 搜索框 -->
		<headers></headers>
		<el-row type="flex" justify="center">
			<el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18">
				<div class="navtext">
					<div>OMNI Explorer</div>
					<div class="triangle"></div>
					<div>Property</div>
				</div>
			</el-col>
		</el-row>
		
		<el-row type="flex" justify="center" style="margin-top: 24rem;"  >
			<el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18" style="background-color: #FFFFFF; padding-left: 40rem; padding-top: 10rem; box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);">
				<el-row type="flex" align="middle" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						<img src="../../assets/omni/USDT.png" class="property-img" />
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						<el-row style="font-weight: bolder;">TetherUS</el-row>
						<el-row><span style="color: #8392A5;">created by</span> 5ed3694e8a4fa8d3ec5c75eb6789492c69e65511522b220e94ab51da2b6dd53f</el-row>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 15rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Total:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						1,335,000,000.00 Tokens
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Name:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						TetherUS
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						PropertyID:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						#31
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Created:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						10/7/2014 12:39 AM
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Data:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						The next paradigm of money.
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Issuer:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						<span style="color: #3498E1;">
							32TLn1WLcu8LtfvweLzYUYU6ubc2YV9eZs
						</span>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Category:
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						Financial and insurance activities
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Divisible:	
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						True
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						URL:	
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						<span style="color: #3498E1;">
							https://tether.to
						</span>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Raw Data:	
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						<span style="color: #3498E1;">
							Click here for raw info
						</span>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="margin-top: 30rem;" >
					<el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
						Registration:	
					</el-col>
					<el-col :xs="10" :sm="14" :md="14" :lg="14" :xl="14">
						This property is not registered with OmniExplorer.info. Please see Promote Your Property for further details.
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import headers from "../../components/header/header.vue"
	export default {
		components:{
			headers
		},
		created(){
			console.log(this.$route)
		}
	}
</script>

<style lang="less" scoped>
	.omniProperty{
		background-color: #F7F7F7;
	}
	.navtext{
		width: 300rem;
		height: 20rem;
		
		margin-top: 20rem;
		font-size: 16rem;
		color: #242424;
		overflow: hidden;
		div{
			float: left;
			line-height: 20rem;
		}
		.triangle{
			white:0;
			height: 0;
			border-left: 6rem solid #242424;
			border-right: 0rem solid #242424;
			border-top: 8rem solid transparent;
			border-bottom: 8rem solid transparent;
			margin:3rem 6rem;
		}
	}
	.property-img{
		width:70rem ;
		height:70rem ;
		
	}
</style>
