<template>
	<div style="background-color: #F7F7F7;overflow: hidden;">
		<!-- 新头部 -->
		<div class="header" style="box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);">
		  <div class="zhong">
			<div class="header_left">
			  <img
				src="../../assets/logo.png"
				alt=""
				style="height: 0.48rem; width: 0.48rem"
			  />
			  <div class="left_text">Block browser</div>
			</div>
			<div class="header_right">
			  <dropdown></dropdown>
			</div>
		  </div>
		</div>
		
		<div style="margin-top: 26px;">
			<headers></headers>
		</div>
		<!-- 小导航 -->
		<div class="minnav">
			<div class="nav_title">
				<div class="hands" @click="goback">Bitcoin Explorer</div>
				<div class="triangle" ></div>
				<div>
					Unconfirmed transactions
				</div>
			</div>
		</div>
		<div class="trantitle">
			<ul class="titles">
				<li style="font-size:0.2rem;font-weight: bold ;">Unconfirmed transactions</li>
				<li>Timestamp</li>
				<li>Size</li>
				<li>Fee</li>
				<li>Value</li>
			</ul>
		</div>
		<!-- 手机端标题 -->
		<div class="shouji_title">
			<ul>
				<li style="font-size:0.2rem;font-weight: bold ;">Unconfirmed transactions</li>
			</ul>
		</div>
		
		<div style="height: 1200px; background-color: #FFFFFF;" class="pc_con">
			<div style="background-color: #FFFFFF; padding: 10px 0; box-sizing: border-box;" v-for="(item,index) in list">
				<ul class="con">
					<li class="links">
						<router-link :to="`/hash/${item.hash}`">
							{{item.hash}}
						</router-link>
					</li>
					<li>{{item.timestamp}}</li>
					<li>{{item.size}}</li>
					<li>{{item.fee/100000000}}</li>
					<li>{{item.value/100000000}}</li>
				</ul>
			</div>
		</div>
		<!-- 手机端内容 -->
		<div class="shouji_con" v-for="(item,index) in list" :key="index">
			<div class="con_div">
				<div class="com_item">
					<div class="item_name">TXID</div>
					<div class="item_value tranpagelink" style="overflow: hidden; text-overflow: ellipsis;">
						<router-link to="`/hash/${item.hash}`">
							{{item.hash}}
						</router-link>
					</div>
				</div>
				<div class="com_item">
					<div class="item_name">Time</div>
					<div class="item_value">{{item.timestamp}}</div>
				</div>
				<div class="com_item">
					<div class="item_name">Size</div>
					<div class="item_value">{{item.size}}</div>
				</div>
				<div class="com_item">
					<div class="item_name">Fee</div>
					<div class="item_value">{{item.fee/100000000}}</div>
				</div>
				<div class="com_item">
					<div class="item_name">Value</div>
					<div class="item_value">{{item.value/100000000}}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import headers from "../header/header.vue"
	import dropdown from "../dropdown/dropdown.vue"
	export default {
		data(){
			return {
				websock:null,
				arr:[],
				list:[]
			}
		},
		components:{
			headers,
			dropdown
		},
		methods:{
			goback(){
				this.$router.push('/')
			}
		},
		created() {
			let baseurl = location.host
			console.log(baseurl)
			// 开发环境
			 // const wsuri = "ws://aexplorer.sectoken.io/ws";
			 // 生产环境
			 const wsuri = `ws://${baseurl}/ws`;
			 this.websock = new WebSocket(wsuri)
			 this.websock.onmessage = this.websocketonmessage;
			 this.websock.onopen = this.websocketonopen;
			 this.websock.onerror = this.websocketonerror;
			 this.websock.onclose = this.websocketclose;
		},
		beforeDestroy(){
			this.websocketclose()
		},
		methods:{
			 websocketonopen(){ //连接建立之后执行send方法发送数据
				this.websocketsend();
			  },
			  websocketonerror(){//连接建立失败重连
				this.initWebSocket();
			  },
			  websocketonmessage(e){ //数据接收
				const redata = JSON.parse(e.data);
				console.log(redata)
				
				
				if(this.arr.length===20){
					this.arr.shift()
					
					let dates = new Date(redata.timestamp*1000)
					var y = dates.getFullYear();
					var m = dates.getMonth() + 1;    
					m = m < 10 ? ('0' + m) : m;    
					var d = dates.getDate();    
					d = d < 10 ? ('0' + d) : d;
					var h = dates.getHours();  
					h = h < 10 ? ('0' + h) : h;  
					var minute = dates.getMinutes();  
					minute = minute < 10 ? ('0' + minute) : minute;    
					redata.timestamp=y + '-' + m + '-' + d+' '+h+':'+minute
					this.arr.push(redata)
					this.list= this.arr
					
						
					console.log(this.list)
					
				}else{
					
					let dates = new Date(redata.timestamp*1000)
					var y = dates.getFullYear();
					var m = dates.getMonth() + 1;    
					m = m < 10 ? ('0' + m) : m;    
					var d = dates.getDate();    
					d = d < 10 ? ('0' + d) : d;
					var h = dates.getHours();  
					h = h < 10 ? ('0' + h) : h;  
					var minute = dates.getMinutes();  
					minute = minute < 10 ? ('0' + minute) : minute;    
					redata.timestamp=y + '-' + m + '-' + d+' '+h+':'+minute
					
										
					this.arr.push(redata)
					
					this.list= this.arr
					
						
					console.log(this.list)
				}
				
			  },
			  websocketsend(Data){//数据发送
				this.websock.send(Data);
			  },
			  websocketclose(e){  //关闭
				this.websock.close()
				console.log('断开连接');
			  },
			  goback(){
				  this.$router.push('/')
			  }
		}
	}
</script>

<style lang="less" scoped="scoped">
	// 小导航
	.minnav{
		width: 13.79rem;
		margin: 0 auto;
		
		@media screen and (max-width:1240px){
			width: 92%;
		}
	}
	.nav_title{
		font-size: 16px;
		color: #242424;
		left: 0;
		right: 0;
		width: 13.79rem;
		margin: 14px auto;
		display: flex;
		align-items: center;
		@media screen and (max-width:1240px) {
			width: 100%;
		}
	}
	.triangle{
		white:0;
		height: 0;
		border-left: 6px solid #242424;
		border-right: 0rem solid #242424;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		margin:3px 6px;
	}
	
	
	.trantitle{
		width: 100%;
		display: none;
		background-color: #EFEFEF;
		@media screen and (min-width:930px){
			display: block;
		}
		.titles{
			font-size: 16px;
			width: 13.79rem;
			margin: 0 auto;
			padding: 10px 0;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media screen and (max-width:1240px){
				width: 92%;
			}
			li:nth-child(1){
				width: 33%;
			}
			li:nth-child(2){
				width: 15%;
			}
			li:nth-child(3){
				width: 10%;
			}
			li:nth-child(4){
				width: 15%;
			}
			li:nth-child(5){
				width: 15%;
			}	
		}	
	}
	.shouji_title{
		background-color: #EFEFEF;
		height: 40px;
		display: none;
		ul{
			width: 13.79rem;
			margin: 0 auto;
			@media screen and(max-width:1240px){
				width: 92%;
			}
			line-height: 40px;
		}
		@media screen and (max-width:931px){
			display: block;
		}
	}
	
	
	
	.con{
		font-size: 16px;
		width: 13.79rem;
		
		color: #707070;
		margin: 0 auto;
		padding: 10px 0;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width:1240px){
			width: 92%;
		}
		li:nth-child(1){
			width: 33%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		li:nth-child(2){
			width: 15%;
		}
		li:nth-child(3){
			width: 10%;
		}
		li:nth-child(4){
			width: 15%;
		}
		li:nth-child(5){
			width: 15%;
		}
	}
	
	
	
	.links{
		color: #0082FF;
		cursor: pointer;
	}
	.links:hover{
		text-decoration: underline;
	}
	
	.transaction{
		width: 100%;
		height: 0.4rem;
		background-color: #EFEFEF;
		display: flex;
		// font-size: 0.2rem;
		// margin: 0 auto;
		// border: 1px solid black;
		.tran_title{
			width: 13.79rem;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.16rem;
			.title_bold{
				font-size: 0.2rem;
				font-weight: bold
			}
			.title_hash{
				width: 30%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.title_times{
				width: 15%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.title_sizes{
				width: 15%;
				overflow: hidden;
				text-overflow: ellipsis;
			}	
		}
	}
	.tran_list{
		width: 100%;
		background-color: #FFFFFF;
		border: 0.01rem solid #EAEAEA;
		padding: 0.13rem 0;
		box-sizing: border-box;
		ul{
			width: 13.79rem;
			font-size: 0.16rem;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.hashs{
				width: 30%;
				overflow: hidden;
				text-overflow: ellipsis;
				
			}
			.times{
				width: 15%;
				overflow: hidden;
				text-overflow: ellipsis;
				
			}
			.sizes{
				width: 15%;
				overflow: hidden;
				text-overflow: ellipsis;
				
			}
		}
	}
	
	
	.lianjie{
		color: #0082FF;
		cursor: pointer;
	}
	.lianjie:hover{
		text-decoration: underline;
	}
	
	// pc端内容
	.pc_con{
		display: none;
		@media screen and (min-width:930px){
			display: block;
		}
	}
	//手机端 
	.shouji_con{
		display: none;
		background-color: #FEFEFE;
		width: 100%;
		border-bottom: #EAEAEA 1px solid;
		@media screen and (max-width:931px){
			display: block;
		}
		.con_div{
			width: 92%;
			margin: 0 auto;
			font-size: 16px;
			.com_item{
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 12px 0;
				.item_name{
					width: 20%;
					
				}
				.item_value{
					width: 70%;
					
				}
			}
		}
	}
	
	
	// 新头部
	.header {
	  height: 0.68rem;
	  width: 100%;
	  background: #fff;
	  display: flex;
	  justify-content: center;
	  .zhong{
		  display: flex; width: 92%; align-items: center;
		  @media screen and (max-width:435px){
		  			  width: 99%;
		  }
	  }
	}
	.header_left {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  
	}
	.left_text {
	  font-size: 20px;
	  font-weight: bold;
	  cursor: pointer;
	  padding-left: 0.2rem;
	  white-space: nowrap;
	  @media screen and (max-width:540px){
	  	font-size: 16px;
		padding-left: 0.1rem;
	  }
	}
	.header_right {
	  border-radius: 14px;
	  margin-left: 0.72rem;
	  @media screen and (max-width:410px){
		  margin-left: 6px;
	  }
	}
</style>
