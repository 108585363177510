// footer商标处
const brand = "SECTOKEN"
// 标签页标题
const titles = "Sectoken Explorer"
// logo右边文案
var BASE_TITLE = "Sectoken Explorer";

const footers = [
	{
		name:"Github",
		address:"http://www.github.com/"
	},
	{
		name:"Twitter",
		address:"http://mobile.twitter.com/"
	},
	{
		name:"Telegram",
		address:"https://sectoken.io"
	},
	{
		name:"Discord",
		address:"https://discord.onl/"
	}
]



const option = [

  {
    value: "Bitcoin Mainnet",
	// api 是host
    api: "btcscan.sectoken.io",
  	bannertitle:'The Bitcoin Blockchain Explorer',
    coin: "BTC",
  	smalltitle:"Bitcoin Explorer"
  },
  {
	value: "Ominicore Mainnet",
    api: "omniscan.sectoken.io",
  	bannertitle:'The Ominicore Blockchain Explorer',
    coin: "BTC",
  	smalltitle:"Ominicore Explorer"
  }
];

const erctop = {
	erc20top:true,
	erc721top:true,
	erc1155top:true
}




export default {
		footers,
		option,
		erctop,
		brand,
		titles,
		BASE_TITLE
}
