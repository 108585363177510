import omnirequests from "./omnirequest.js"

// 查询首页区块列表
export const omniblockList = (size,page=1)=>{
	
	return omnirequests({url:`/omni/block/?size=${size}&page=${page}`,method:"get"})
}

export const omnilist = (url,method="get")=>{
	return omnirequests({url,method})
}

